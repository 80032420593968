@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.progs-4-psychologists {
  padding-top: 100px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/images/backgrounds/bg-2@2x.png");
  margin-bottom: 50px;

  @include mobile {
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: url("../../../assets/images/backgrounds/bg-2-mobile@2x.jpg");
    margin-bottom: 0;
  }
}

.progs-4-psychologists__inner {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px;
  background-color: #FFEFAA;
  border-radius: 999rem;

  @include mobile {
    padding: 20px 33px 29px;
    border-radius: 50px;
  }
}

.progs-4-psychologists__title {
  color: $color-dot-dark;
  margin-top: 0;
  margin-bottom: 10px;
}

.progs-4-psychologists__subtitle {
  max-width: 392px;
  color: $color-dot-dark;
  margin-top: 0;
  margin-bottom: 10px;
  margin: 0 auto 10px auto;

  @include mobile {
    padding: 0 34px;
  }
}









