@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.header {
  position: relative;
  background-color: #fbfbfb;

  @include tablet {
    position: relative;
  }
}
.header-uz {
  padding: 32px;
  @include mobile {
    padding: unset;
  }

  .header__wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    @include tablet {
      display: none;
      padding: 93px 30px 120px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      background-color: #fbfbfb;
      z-index: z(header-wrapper);
    }

    .header__nav-list-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}

.header__toggle {
  display: none;

  @include tablet {
    position: absolute;
    top: 25px;
    right: 28px;
    display: block;
    width: 24px;
    height: 24px;
    padding: 0;
    background: none;
    border: none;

    span {
      position: absolute;
      left: 4px;
      display: block;
      height: 2px;
      width: 16px;
      background-color: $color-dot-dark;
      border-radius: 999rem;

      &:nth-child(1) {
        top: 5px;
      }

      &:nth-child(2) {
        top: 11px;
      }

      &:nth-child(3) {
        top: 17px;
      }
    }
  }

  .header--opened & {
    span {
      &:nth-child(1) {
        top: 11px;
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        top: 11px;
        transform: rotate(-45deg);
      }

      &:nth-child(3) {
        top: 11px;
        transform: rotate(45deg);
      }
    }
  }
}

img {
  height: 41px;
}

.header__logo {
  display: block;
  @include mobile {
    width: unset;
  }
}

.header__logotypies {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.logo {
  position: relative;
  left: -50px;
  max-width: 150%;
}

.header__wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;

  @include tablet {
    display: none;
    padding: 93px 30px 120px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: #fbfbfb;
    z-index: z(header-wrapper);

    .header--opened & {
      display: flex;
      flex-direction: column;
    }
  }
}

.header__nav {
  display: flex;
  align-items: center;
  padding-top: 57px;
  padding-bottom: 32px;
  min-width: 77%;

  @include tablet {
    display: block;
    padding: 0;
  }
}

.header__top {
  @include tablet {
    position: relative;
    height: 70px;
    padding: 20px;
    background-color: #fbfbfb;
    z-index: z(header-top);
  }

  @include mobile {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.header__nav-list-wrap {
  margin-left: auto;
  margin-right: auto;

  @include tablet {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    overflow-y: auto;
  }
}

.header__nav-list {
  @include list();

  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  @include tablet {
    display: block;
    width: 100%;
  }
}

.header__nav-item {
  cursor: pointer;

  &--dropdown {
    position: relative;

    .header__nav-link {
      position: relative;
      padding-right: 34px;

      &--profile {
        margin: 0 auto;
        max-width: 316px;
        position: relative;
        padding: 10px 34px 10px 13px;
        background-color: #f4f4f4;
        border-radius: 40px;
        display: flex;
        align-items: center;
        gap: 8px;

        &::after {
          @include pseudo;
          right: 8px;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px 7px;
          background-image: url('../../../1_shared/assets/pictures/stack/stack.svg#arrow-down');
        }
      }

      &::after {
        @include pseudo;
        top: 7px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px 7px;
        background-image: url('../../../1_shared/assets/pictures/stack/stack.svg#arrow-down');
      }
    }
  }

  &.is-active {
    .header__nav-link::after {
      transform: rotate(180deg);
    }
  }

  @include tablet {
    width: 100%;
    margin-bottom: 20px;

    &--dropdown {
      .header__nav-link {
        padding-left: 24px;
        padding-right: 24px;

        &::after {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.header__nav-link {
  display: inline-block;
  padding: 10px;
  font-size: 14px;
  line-height: 1.28;
  font-weight: 500;
  color: $color-dot-dark;

  &.current {
    font-weight: 700;
  }

  @include tablet {
    width: 100%;
    padding: 7px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
  }
}

.header__nav-sublist {
  @include list();
  z-index: z('popup');
  position: absolute;
  left: 0;
  top: 100%;
  background-color: $color-white;

  @include tablet {
    position: static;
    background-color: #fbfbfb;
  }
}

.header__nav-subitem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.header__logout {
  background-color: transparent;
  padding: 0;
}

.header__nav-sublink {
  @include tablet {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

.header__login {
  position: relative;
  display: inline-block;
  padding: 10px 10px 10px 39px;
  font-size: 14px;
  line-height: 1.28;
  font-weight: 500;
  color: $color-dot-dark;
  background-color: #f4f4f4;
  border-radius: 40px;

  &::before {
    @include pseudo;

    top: 7px;
    left: 10px;
    width: 24px;
    height: 24px;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 24px 25px;
    mask-image: url('../../../1_shared/assets/pictures/stack/stack.svg#login-enter');
    background-color: $color-dot-dark;
  }

  @include tablet {
    width: 100%;
    padding: 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &::before {
      position: static;
      display: inline-block;
      margin-right: 4px;
      vertical-align: middle;
    }
  }
}
