.root {
  display: grid;
  align-items: center;
  grid-template-columns: 0.9fr 0.1fr;
  background-color: #E6F6FF;
  border-radius: 25px;
}

.text {
  padding: 8px 0 8px 10px;
  white-space: nowrap;
}

.imgButton {
  padding: 5px;
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: #150058;
    transition: all 0.2ms;
  }
}

.imgClose {
  max-width: unset !important;
  width: 24px;
  aspect-ratio: 1/1;
}
