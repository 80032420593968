@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.achievement {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f3faff;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.achievement__container {
  display: flex;

  @include tablet {
    display: block;
  }
}

.achievement__list {
  @include list();
  max-width: 78px;
  margin-left: auto;
  margin-right: auto;

  @include tablet {
    display: flex;
    justify-content: space-between;
    max-width: 355px;
  }
}

.achievement__item {
  margin-bottom: 19px;
  text-align: center;
}

.achievement__item-value {
  position: relative;
  display: inline-block;
  min-width: 41px;
  padding: 3px 12px 0;
  margin-bottom: 2px;
  font-family: $font-secondary;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: $color-dot-dark;

  &::before,
  &::after {
    @include pseudo();

    bottom: 2px;
    width: 25px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 25px 24px;
  }

  &::before {
    left: 0;
    background-image: url("../../../assets/pictures/stack/stack.svg#left-achievement");
  }

  &::after {
    right: 0;
    background-image: url("../../../assets/pictures/stack/stack.svg#right-achievement");
  }

  &--star {
    padding-top: 9px;
    background-image: url("../../../assets/pictures/stack/stack.svg#star-achievement");
    background-repeat: no-repeat;
    background-size: 10px 9px;
    background-position: center top;
  }

  @include tablet {
    padding-top: 9px;
  }
}

.achievement__item-description {
  font-weight: 500;
  font-size: 10px;
  line-height: 1.21;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-dot-grey;

  @include mobile {
    font-size: 8px;
    line-height: 1.25;
  }
}

.achievement__item-title {
  width: 36.5%;
  padding-left: 37px;
  margin-bottom: 0;
  font-family: $font-secondary;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: $color-dot-dark;

  @include tablet {
    width: 100%;
    padding-left: 0;
    margin-bottom: 10px;
  }

  @include mobile {
    font-size: 20px;
    line-height: 1.4;
  }
}

.achievement__year {
  padding-top: 5px;
  padding-left: 13px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-dot-blue;

  @include tablet {
    width: 63px;
    flex-shrink: 0;
    padding-left: 0;
  }
}

.achievement__box-desc {
  max-width: 460px;
  margin: 0;
  color: $color-black;

  @include tablet {
    max-width: none;
  }
}

.achievement__list-second {
  @include list();

  max-width: 900px;

  @include tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }
}

.achievement__item-second {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet {
    display: block;
  }

  @include mobile {
    margin-bottom: 20px;
  }
}

.achievement__box {
  flex-grow: 1;
}

.achievement__btn-number {
  display: none;
  padding: 3px 10px;
  margin-left: 112px;
  margin-top: 5px;

  @include tablet {
    margin-left: 63px;
  }
}

.achievement__box-list {
  @include list();

  &.close {
    + .achievement__btn-number {
      display: inline-block;
    }

    .achievement__box-item {
      margin-bottom: 0;
    }

    .achievement__box-item:not(:first-child) {
      display: none;
    }
  }
}

.achievement__box-item {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    padding-top: 0;
  }
}

.achievement__btn {
  padding: 8px 26px;
  margin-left: 112px;
  margin-top: 5px;

  @include tablet {
    margin-left: 63px;
  }
}
