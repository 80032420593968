@import "app/styles/global/mixins";

.video__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 190px;
  height: 300px;
  padding: 10px;
  border-radius: 21px;
}

.video__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  border-radius: 15px;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.video__suptitle {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 0.5) !important;
}

.video__item-title {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #fff !important;
}

.video__time {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.video__button {
  @include center_transform();
  z-index: 3;
  background-color: var(--white-color);
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 0;

  .button-play {
    @include center_transform();
    width: 18px;
    height: 18px;
    mask-image: url("../../../assets/pictures/stack/stack.svg#play");
    background-color: var(--primary-btn-color);
  }
}

.video__box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 21px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}