@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 100px;
}

.now {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.fixData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.titleNow {
  z-index: 1;
}

.headerTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goToHistory {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 16px;
  }
}

.sessionNow {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 68px 0;
}

.cardNow {
  width: 80%;
  padding: 20px;
  position: relative;
  display: grid;
  grid-template-areas: 'IMAGE TITLE BTN';
  background-color: var(--white-color);
  z-index: 1;
  border-radius: 20px;
  grid-gap: 20px;
  grid-template-columns: 180px 1fr 1fr;

  @include tablet {
    width: auto;
    padding: 10px;
    grid-template-areas:
      'IMAGE TITLE'
      'BTN BTN';
    grid-template-columns: 90px 1fr;
    grid-template-rows: 1fr auto;
  }
}

.img {
  grid-area: IMAGE;
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  grid-area: TITLE;
  flex-direction: column;
  justify-content: space-between;
}

.nameBlock {
  display: flex;
  flex-direction: column;
}

.weightTitle {
  font-size: 36px;
  font-weight: 400;

  @include tablet {
    font-size: 24px;
  }
}

.infoBlock {
  display: flex;
  gap: 40px;
}

.textWrap {
  display: flex;
  flex-direction: column;
}

.btnWrap {
  grid-area: BTN;
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet() {
    justify-content: unset;
  }
}

.bigBtn {
  display: flex;
  align-items: center;
  padding: 117.5px 160px;
  border-radius: 127px;

  @include tablet {
    width: 100%;
    padding: 25px 40px;
    border-radius: 25px;
  }
}

.videoWrap {
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  transform: translateY(-50%);
}

.videoItem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tableCont {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.columnsTitle {
  position: relative;
  display: grid;
  grid-template-columns: 165px 165px 150px 150px 70px 135px auto;
  padding: 10px 0;
  grid-gap: 20px;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
  }
}

.item {
  position: relative;
  display: grid;
  grid-template-columns: 165px 165px 150px 150px 70px 135px auto;
  padding: 20px 0;
  grid-gap: 20px;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
  }
}

.subItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.longText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnText {
  display: flex;
  min-width: 165px;
  align-items: center;
  background: transparent;
  gap: 5px;
  border: none;
  border-radius: 12px;
  .calendar {
    width: 24px;
    height: 24px;
    mask-image: url('../../../1_shared/assets/pictures/stack/icons/calendar.svg');
    background-color: var(--gray-color);
    background-repeat: no-repeat;
  }

  &:hover {
    .btnTextDescr {
      color: var(--primary-btn-color);
    }
    .calendar {
      background-color: var(--primary-btn-color);
    }
  }
}

.btnDanger {
  background: #ffe3e1 !important;
  color: #cc3b33 !important;
}

.notFoundSessions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
