@import "app/styles/global/variables";
@import "app/styles/global/mixins";

.root {
  display: inline;
  position: relative;
  width: auto;
}

.root:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 10px;
  height: 4px;
  background-color: $color-dot-blue;
}