@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.air-datepicker {
  // --adp-cell-background-color-selected: #3C00FF;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  padding: 5px;
}

.air-datepicker-cell {
  font-family: $font-main;
  color: $color-dot-dark;
  font-size: 14px;
  line-height: 1.28;
  background-color: #F3FAFF;
  border-radius: 5px;
  margin: 2px;

  &.-disabled- {
    color: $color-dot-grey;
  }

  &.-current- {
    color: $color-dot-blue;
    font-weight: 500;
  }

  &.-current-.-focus- {
    color: $color-white;
  }

  &.-in-range-,
  &.-range-from-,
  &.-selected-,
  &.-selected-.-focus-,
  &.-focus-,
  &.-current-.-selected- {
    color: $color-white;
    background-color: $color-dot-blue;
    border-radius: 5px;
  }

  &.-range-to-,
  &.-range-from- {
    border: none;
  }
}

.air-datepicker-nav--title {
  font-family: $font-main;
  color: $color-dot-dark;
  font-size: 14px;
  line-height: 1.71;
  font-weight: 500;
}

.air-datepicker-nav {
  justify-content: center;
  border-bottom: none;
}

.air-datepicker-body--day-name {
  font-family: $font-main;
  color: $color-dot-blue;
  font-weight: 500;
}

.air-datepicker--pointer::after {
  border: none;
}

.catalog-form {
  max-width: 700px;

  .catalog-form__field-wrap--time {
    .custom-select__placeholder {
      top: 7px;
    }
  }

  .custom-select__placeholder {
    top: 24px;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $color-dot-grey;
  }

  input,
  select {
    width: 100%;
    height: 38px;
    padding: 0 34px 0 10px;
    font-family: $font-main;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    color: $color-dot-dark;
    border: 1px solid $color-dot-grey;
    border-radius: 5px;
    background-color: $color-white;

    &:hover,
    &:focus-visible {
      border-color: #0075FF;
      outline: none;
    }

    &:disabled {
      color: $color-dot-grey;
      background-color: #F9F9F9;
    }
  }

  // select {
  //   padding-right: 34px;
  //   appearance: none;
  //   background-image: url("../../../assets/pictures/stack/stack.svg#arrow-select");
  //   background-repeat: no-repeat;
  //   background-size: 24px 24px;
  //   background-position: top 7px right 10px;
  // }

  @include mobile {
    height: 0;
    overflow: hidden;
    transition: height 0.6s;

    form {
      padding-bottom: 20px;
      row-gap: 0;
    }
  }

  .catalog-form__checkbox {
    label {
      padding-top: 0;
      margin-bottom: 7px;
      font-family: $font-main;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-dot-dark;
      text-transform: none;
      letter-spacing: 0;

      @include mobile {
        font-size: 14px;
      }
    }
  }
}

.catalog-form__field {
  &--big {
    width: 308px;
  }

  &--middle {
    width: 157px;
  }

  &--min {
    width: 117px;
  }

  &--datetime {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--checkbox {
    align-self: flex-end;
  }

  @include mobile {
    margin-bottom: 20px;

    &--big {
      width: 100%;
    }

    &--datetime {
      width: 320px;
    }
  }
}

.catalog-form__field-wrap {
  position: relative;

  &::before {
    @include pseudo();

    top: 7px;
    right: 10px;
    width: 24px;
    height: 24px;
    mask-repeat: no-repeat;
    background-color: $color-dot-grey;
  }

  &:hover,
  &:focus-within {
    &::before {
      background-color: $color-dot-blue;
    }
  }

  &--date {
    width: 150px;

    &::before {
      mask-image: url("../../../assets/pictures/stack/stack.svg#calendar");
    }

    &:has(input:not(:placeholder-shown)) {
      &::before {
        background-color: $color-dot-blue;
      }
    }

    input {
      &:not(:placeholder-shown) {
        border-color: $color-dot-blue;
        background-color: #F3FAFF;
      }
    }
  }

  &--time {
    width: 150px;

    // &::before {
    //   mask-image: url("../../../assets/pictures/stack/stack.svg#clock");
    //   z-index: 2;
    // }

    // select {
    //   background-image: none;
    // }

    .custom-select__icon {
      mask-image: url("../../../assets/pictures/stack/stack.svg#clock");
    }
  }
}
