@import 'app/styles/breakpoints';
.root {
  display: grid;
  grid-template-rows: auto auto;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: auto;
    margin-bottom: 1rem;
  }
}

.icon {
  @media screen and (max-width: map-get($breakpoints, md)) {
    width: 18px;
    height: 18px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.filterRow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;

  > div {
    flex: 1 auto auto;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.superVisionSwitch {
  display: flex;
  align-items: end;
  gap: 20px;
  margin-top: 15px;
}

.priceSexContainer,
.experienceDayTypeContainer,
.dateLocalTimeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.workWith {
  width: 100% !important;
}

.error {
  color: red;
}

.selected {
  border-color: #3c00ff !important;
  background: #f3faff !important;
  color: #4f5152 !important;
  div {
    background: #f3faff !important;
    color: #4f5152 !important;
    border-color: #3c00ff !important;
  }

  .ant-input {
    border-color: #3c00ff !important;
    background: #f3faff !important;
    color: #4f5152 !important;
  }
}
