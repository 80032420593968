@import "../../../app/styles/global/mixins";
@import "../../../app/styles/global/variables";

.testing {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 138px;
  padding-bottom: 195px;
  background-color: #f3f9fe;

  @include mobile {
    padding: 50px 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../1_shared/assets/images/backgrounds/dots-2@2x.png");
  }
}

.testing__inner {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 760px;
  background-color: $color-white;
  border-radius: 20px;

}

.testing__tabs-controls {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.testing__tabs-control {
  display: inline-block;
  position: relative;
  color: #D9D9D9;
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-top: 8px;

  &::before {
    @include pseudo();

    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #D9D9D9;
    border-radius: 999rem;
  }

  &.active {
    color: $color-dot-blue;

    &::before {
      background-color: $color-dot-blue;
    }
  }
}

.testing__tabs-item {
  display: none;

  &.active {
    display: block;
  }
}

.testing__tabs-title {
  margin-top: 40px;
  margin-bottom: 20px;
}

.testing__radio-group {
  margin-bottom: 40px;

  &--row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .testing__radio-label {
      margin-right: 20px;
    }
  }
}

.testing__radio-group-heading {
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
}

.testing__radio-label {
  display: block;
  margin-bottom: 20px;
  cursor: pointer;

  span {
    position: relative;
    display: inline-block;
    color: $color-dot-grey;
    font-weight: 500;
    padding-left: 27px;

    &::before {
      @include pseudo();

      top: 0;
      left: 0;
      display: block;
      width: 22px;
      height: 22px;
      border: 2px solid $color-dot-grey;
      border-radius: 50%;
    }

    &::after {
      @include pseudo();

      display: none;
      top: 5px;
      left: 5px;
      width: 12px;
      height: 12px;
      background-color: $color-dot-blue;
      border-radius: 50%;
    }
  }

  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;

    &:checked {
      + span {
        color: $color-dot-dark;

        &::before {
          border-color: $color-dot-blue;
        }

        &::after {
          display: block;
        }
      }
    }
  }

  &:hover {
    span {
      color: $color-dot-dark;

      &::before {
        border-color: $color-dot-blue;
      }
    }
  }
}

.testing__btn-wrap {
  display: flex;

  @include mobile {
    display: block;
  }
}

.testing__btn {
  &--next {
    min-width: 256px;
    margin-left: auto;
  }

  &--prev {
    min-width: 141px;
    color: $color-dot-blue;
    background-color: #F3FAFF;

    &:hover {
      color: $color-white;
    }
  }

  @include mobile {
    width: 100%;

    &--prev {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
}

.testing__accordion {
  @include list();

  margin-bottom: 40px;
}

.testing__accordion-item {
  margin: 0 -50px;
  border-bottom: 1px solid #E8E8E8;

  &.is-active {
    .testing__accordion-link {

      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  @include mobile {
    margin: 0;
    // padding: 10px;
  }
}

.testing__accordion-link {
  display: block;
  position: relative;
  padding: 20px 50px 20px 50px;
  padding-right: 34px;

  &::after {
    @include pseudo();

    top: 50%;
    right: 60px;
    width: 24px;
    height: 24px;
    background-color: #F3FAFF;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center top 10px;
    background-size: 12px 6px;
    background-image: url("../../../1_shared/assets/pictures/stack/stack.svg#arrow-down-blue");
    transform: translateY(-50%);

    @include mobile {
      right: 10px;
    }
  }

  @include mobile {
    padding: 10px;
  }
}

.testing__accordion-content {
  padding: 0 50px;

  @include mobile {
    padding: 0 10px;
  }
}

.testing__accordion-list {
  @include list();

  padding-bottom: 10px;
}

.testing__tag {
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;

    &:checked {
      + span {
        position: relative;
        color: $color-white;
        background-color: $color-dot-blue;
        padding-left: 15px;
        padding-right: 39px;

        &::after {
          display: block;
        }
      }
    }
  }

  span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 1.28;
    border: 1px solid $color-dot-blue;
    border-radius: 999rem;
    padding: 8px 27px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    &::after {
      display: none;
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border: none;
      box-shadow: none;
      background-color: transparent;

      @include icon-bg();

      background-image: url("../../../1_shared/assets/pictures/stack/stack.svg#close");
    }
  }
}






