@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  padding: 0 50px;

  @include mobile() {
    padding: 0 20px;
  }
}

.accordion {
  padding: 0;
}

.wrapperItem {
  padding: 20px 50px;
}

.accordionItem {
  list-style: none;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    background-color: #e8e8e8;
    width: 100%;
    height: 1px;
  }

  &:last-child {
    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      background-color: #e8e8e8;
      width: 100%;
      height: 1px;
    }
  }

  &.is-active {
    .wrapperItem {
      .accordionBtn {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.accordionBtn {
  display: flex;
  justify-content: space-between;

  &::after {
    @include pseudo();
    @include icon-bg();
    width: 24px;
    height: 24px;
    right: 20px;
    border-radius: 50%;
    background-color: #f3faff;
    background-image: url('../../../1_shared/assets/pictures/arrow.svg');
    transition: 0.3s all;
  }
}

.chips {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.active {
  background-color: var(--primary-btn-color);
  color: #fff;
}

.contentChips {
  display: flex;
  align-items: center;
  gap: 5px;
}

div.activeContent {
  max-height: 100% !important;
}
