@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.programs {
  padding-top: 44px;
  padding-bottom: 53px;
  color: var(--footer-text-color);
  background: var(--footer-bg);

  &--light {
    background-color: $color-white;

    .programs__title {
      color: $color-dot-dark;
    }

    .programs__item-title {
      color: $color-dot-dark;
    }
  }

  @include mobile {
    padding-top: 20px;
    padding-bottom: 35px;

    &--light {
      padding-bottom: 20px;
    }
  }
}

.programs__title {
  color: var(--footer-text-color);
  margin-top: 0;
  margin-bottom: 20px;
}

.programs__slider {
  padding: 0 20px;

  @include laptop {
    padding-left: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.programs__item {
  width: 261px;

  @include tablet {
    width: 150px;
  }
}

.programs__link {
  display: block;
}

.programs__image {
  img {
    width: 261px;
    height: 460px;
    border-radius: 20px;
    object-fit: cover;
  }

  @include tablet {
    img {
      width: 130px;
      height: 230px;
      border-radius: 10px;
    }
  }
}

.programs__tags {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-dot-grey;

  span {
    position: relative;
    display: inline-block;
    margin-right: 12px;

    &:not(:last-child)::after {
      @include pseudo();

      top: 5px;
      right: -8px;
      width: 3px;
      height: 3px;
      background-color: $color-dot-grey;
      border-radius: 50%;
    }
  }

  @include tablet {
    font-size: 8px;

    span {
      margin-right: 7px;

      &:not(:last-child)::after {
        top: 4px;
        right: -6px;
      }
    }
  }
}

.programs__item-title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  font-family: $font-main;
  font-size: 16px;
  line-height: 1.5;
  color: var(--footer-text-color);

  @include tablet {
    font-size: 14px;
    line-height: 1.43;
  }
}
