@import '../../../variables';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.textWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.footer {
  display: flex;
  justify-content: space-between;
}

.feedbackAccepted {
  color: green;
  font-weight: 500;
  text-align: center;
}