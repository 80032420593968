@import 'app/styles/breakpoints';
@import 'app/styles/global/variables';

.root {
  position: relative;
  overflow-x: auto;
}

.tableCont {
  display: table;
  width: 100%;
  border-spacing: 0 2px;
  @media screen and (max-width: map-get($breakpoints, sm)) {
    min-width: 800px;
  }
}

.columnsTitle {
  position: relative;

  th {
    padding: 10px;
    border-bottom: 1px solid $color-gray;
  }
}

.item {
  position: relative;
  z-index: 1;

  td {
    padding-left: 10px;
    border-bottom: 1px solid $color-gray;
  }
}

.notPaidItem {
  background-color: var(--light-red-color);
}

.subItem {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.notPaidStatus {
  color: var(--danger-color);
}

.longText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnText {
  display: flex;
  min-width: 165px;
  align-items: center;
  background: transparent;
  gap: 5px;
  border: none;
  border-radius: 12px;
  .calendar {
    width: 24px;
    height: 24px;
    mask-image: url('../../../1_shared/assets/pictures/stack/icons/calendar.svg');
    background-color: var(--gray-color);
    background-repeat: no-repeat;
  }

  &:hover {
    .btnTextDescr {
      color: var(--primary-btn-color);
    }
    .calendar {
      background-color: var(--primary-btn-color);
    }
  }
}

.btnDanger {
  background: #ffe3e1 !important;
  color: #cc3b33 !important;
}
