@import "mixins";
@import "variables";


.btn {
  display: inline-block;
  padding: 15px 20px;
  font-family: var(--m-font-family), sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.21;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  border-radius: 999rem;
  border: none;

  &--violet {
    color: var(--white-color);
    background-color: var(--primary-btn-color);
  }

  &--light {
    color: var(--secondary-btn-text-color);
    background-color: var(--secondary-btn-color);

    &:hover {
      color: var(--secondary-btn-text-hover-color);
      background-color: var(--secondary-btn-hover-color);
    }
  }

  &--white {
    color: var(--primary-btn-color);
    background-color: #fff;

    &:hover {
      color: var(--white-color);
    }
  }

  &--dark {
    color: var(--primary-btn-color);
    background-color: #151F2C;
    border: 2px solid var(--primary-btn-color);
  }

  &--mini {
    padding: 8px 16px;
    font-size: 12px;
  }

  &:hover {
    background-color: var(--primary-btn-hover-color);
  }

  @include mobile {
    padding: 10px 10px;
  }

  &--survay-discount-btn {
    @include mobile {
      padding: 15px;
    }
  }
}
