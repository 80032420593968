@import "app/styles/breakpoints";

.infoSpec {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;



  @media screen and (max-width: map-get($breakpoints, md)){
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.defaultInfo {
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto auto;

  @media screen and (max-width: map-get($breakpoints, md)){
    grid-template-columns: auto auto auto;
  }
}

.infoSpecBloc {
  display: grid;
  gap: 5px;
  grid-template-rows: 0.5fr 0.5fr;
}

.titleInfo {
  font-weight: bold;
}

.superVision {
  background: #7f7fff;
  border: 1px solid #7f7fff !important;
  color: white !important;

  &:hover {
    color: white;
  }
}

.textChips {
  font-size: 12px;
}

.textChipsSuper {
  color: white !important;
}

.darkText {
  color: var(--white-color);
}