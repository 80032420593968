@import 'app/styles/global/variables';
@import 'app/styles/global/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  margin: 0 0 5rem;

  @include tablet {
    height: auto;
    padding: 0;
    margin: 40px 0;
  }
}

.notPaidRow {
  background: $color-red-light;
}

.btnDanger {
  background: $color-red-light !important;
  color: $color-red-danger !important;
}