@import "app/styles/breakpoints";


.btn {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 14px;
  padding: 2px 1.25rem;
  letter-spacing: 1.1px;
  border: none;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: map-get($breakpoints, sm)){
    padding: 2px 1rem;
  }
}

.secondary {
  background-color: var(--secondary-btn-color);
  color: var(--secondary-btn-text-color);
  &:disabled {
    pointer-events: none;
    background-color: var(--gray-color) !important;
    color: var(--white-color);
  }
  &:hover {
    &:not(:disabled) {
      color: var(--secondary-btn-text-hover-color) !important;
      background-color: var(--secondary-btn-hover-color) !important;
    }
  }
}
