.root {
  position: absolute;
  border: 1px solid #FFFDE9;
  display: grid;
  padding: 5px;
  grid-template-rows: auto auto;
  background-color: #FFFDE9;
  width: 100%;

}

.btn {
  background-color: transparent;
  position: absolute;
  z-index: 11;
  top: 5px;
  right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: all 0.3ms ease-in-out;

  &:hover {
    background-color: orangered;
    border-radius: 50%;

    &:after {
      background-color: white;
    }

    &:before {
      background-color: white;
    }
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 15px;
    transform: rotate(45deg);
    background-color: orangered;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 15px;
    transform: rotate(-45deg);
    background-color: orangered;
  }
}

.rootDeleteble {
  z-index: 11;
  &:hover {
    border: 1px solid orangered;
    border-radius: 5px;
    z-index: 12;
    -webkit-box-shadow: 8px 12px 8px -2px rgba(34, 60, 80, 0.15);
    -moz-box-shadow: 8px 12px 8px -2px rgba(34, 60, 80, 0.15);
    box-shadow: 8px 12px 8px -2px rgba(34, 60, 80, 0.15);
  }
}

.rootPrev {
  background-color: #F5F5F5 !important;
  border: 1px solid #F5F5F5;
}