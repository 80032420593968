.fieldRow {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
}

.label {
}

.select,
.input {
  min-height: 38px;
  height: auto;
  width: 100%;
}

.mainPromoInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}