.label {
  color: var(--gray-color);
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: 500;
  padding-bottom: 0.3125rem;
  height: min-content;
  white-space: nowrap;
  text-transform: uppercase;
  display: inline-block;

  &Uppercase {
    text-transform: uppercase;
  }
}
