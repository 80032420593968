@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.subscribe-block {
  padding: 50px 0;

  @include mobile {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.subscribe-block__container {
  @include mobile {
    padding-left: 37px;
    padding-right: 37px;
  }
}

.subscribe-block__inner {
  display: flex;
  text-align: center;
  align-items: center;
  gap: 80px;
  padding: 67px 100px;
  background-color: #fffde9;
  border-radius: 999rem;

  @include mobile {
    display: block;
    padding: 110px 29px;
  }
}

.subscribe-block__heading {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: column;

  @include mobile {
    max-width: 310px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-align: center;
  }
}

.btnWrap {
  margin-top: 2.5%;

  @include mobile {
    width: auto;
  }
}

.subscribe-block__title {
  margin-top: 0;
  margin-bottom: 10px;
}

.subscribe-block__subtitle {
  margin: 0;
}

.subscribe-block__form {
  display: flex;
  flex-direction: column;

  label {
    display: inline-block;
    color: $color-dot-grey;
    font-size: 10px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    max-width: 330px;
    height: 38px;
    padding: 0 10px;
    font-family: $font-main;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33;
    color: $color-dot-dark;
    border: 1px solid $color-dot-grey;
    border-radius: 5px;
    background-color: $color-white;
  }

  span {
    color: $color-dot-grey;
    font-size: 12px;
    line-height: 1.5;
  }

  @include mobile {
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;

    label {
      font-size: 8px;
    }

    input {
      max-width: 100%;
    }

    span {
      padding-left: 50px;
      padding-right: 50px;
      text-align: center;
    }
  }
}

.subscribe-block__btn {
  display: inline-block;
  align-self: baseline;
  margin-top: 10px;
  margin-bottom: 15px;
  background-color: var(--primary-btn-color);
  color: var(--white-color);

  @include mobile {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
  }

  &:hover {
    background-color: var(--primary-btn-hover-color);
  }
}
