@import "../../../app/styles/global/mixins";
@import "../../../app/styles/global/variables";

.video {
  padding-top: 50px;
  padding-bottom: 50px;

  @include mobile {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.video__title {
  margin-top: 0;
  margin-bottom: 10px;
}

.video__slider {
  padding-left: calc((100% - 1160px) / 2);
  padding-right: 20px;

  @include laptop {
    padding-left: 20px;
  }

  @include mobile {
    padding-left: 10px;
  }
}

.video__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 190px;
  height: 300px;
  padding: 10px;
  border-radius: 21px;
}

.video__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  border-radius: 15px;
  background: var(--primary-btn-color);
  z-index: 2;
}

.video__suptitle {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.video__item-title {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #fff;
}

.video__time {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.video__button {
  z-index: 3;
}

.video__box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 21px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}
