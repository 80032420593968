@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.specialistsItem {
  cursor: pointer;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  h3 {
    font-weight: 400;
    color: var(--footer-text-color);
    font-size: 16px;
    line-height: 1.5;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  p {
    color: $color-dot-grey;
    font-size: 10px;
    line-height: 1.5;
    padding-right: 12px;
    margin: 0;
  }

  @include mobile {
    width: 174px;
  }
}

.marker {
  max-width: 311px;
  color: $color-dot-grey;
  padding: 6px 0;
}

.specBlock {
  display: flex;
  flex-direction: column;
  gap: 31px;
  justify-content: space-between;
}

.priceSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 100%;
  vertical-align: bottom;
  text-transform: uppercase;
  color: $color-dot-grey !important;
  font-family: var(--title-font-family), sans-serif !important;
}

.priceBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.price {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 32px;
  letter-spacing: 0;
  color: $color-dot-grey !important;
  font-family: var(--title-font-family), sans-serif !important;
}

.discountedPrice {
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 32px;
  letter-spacing: 0;
  color: $color-white !important;
  font-family: var(--title-font-family), sans-serif !important;
}

.priceStrike {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0.2em; // Толщина линии
    background-color: #3c00ff;
    transform: translateY(-50%);
    rotate: -5deg;
  }
}
