@import 'app/styles/global/variables';
@import 'app/styles/global/mixins';

.root {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin: 60px 0;
  @include tablet {
    padding: 40px;
  }
}

.fieldContainer {
  width: 450px;
  @include tablet {
    width: 100%;
  }
}

.inputStyle {
  height: 38px;
}

.error {
  color: $color-red-error;
}