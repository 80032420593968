@import 'app/styles/breakpoints';

.breadcrumbs {
  font-family: Inter, sans-serif;
  font-size: 10px;
  a {
    &:hover {
      background: none;
    }
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    height: 20px;
  }
  @media screen and (max-width: map-get($breakpoints, xs)) {
    font-size: 8px;
  }
}
