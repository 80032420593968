.imageWrapper {
  width: 300px;
  border-radius: 20px;
  overflow: hidden;
  max-height: 533px;
}

.image {
  width: 300px !important;
  object-fit: cover !important;
  aspect-ratio: 9/16 !important;
}