@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  flex-direction: column;
}

.radioChecked {
  background-color: var(--primary-btn-color);
  color: white;
  text-overflow: ellipsis;
  span {
    color: white;
  }
}

.disabled {
  background-color: var(--secondary-btn-disabled-color) !important;
  color: white;
  span {
    color: white;
  }

  &:hover {
    background-color: var(--secondary-btn-disabled-color) !important;
    color: white;
    span {
      color: white;
    }
  }
}

.radioGroup {
  display: flex;

  .radio {
    display: flex;
    align-items: center;
    justify-items: center;
    height: 40px;
    width: 100%;
    background-color: var(--secondary-btn-color);
    border-radius: 5px !important;
    border: none !important;

    &:hover {
      background-color: var(--primary-btn-color);
      color: white;
      span {
        color: white;
      }
    }

    &:first-child {
      border-radius: 5px 0 0 5px !important;
    }

    &:last-child {
      padding: 0 10px;
      border-radius: 0 5px 5px 0 !important;
    }

    @include tablet {
      span {
        line-height: 12px;
      }
    }
  }

  .radioChecked {
    background-color: var(--primary-btn-color);
    color: white;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
}
