@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.custom-select {
  position: relative;

  width: 100%;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  &__label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $color-dot-grey;
    pointer-events: none;
  }

  &__placeholder {
    position: absolute;
    top: 24px;
    top: 7px;
    left: 10px;

    font-family: $font-main;
    color: $color-dot-grey;

    transition: transform 0.3s ease, opacity 0.3s ease;

    pointer-events: none;
  }

  select {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    padding: 0 10px;
    font-family: $font-main;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: $color-dot-dark;
    border: 1px solid $color-dot-grey;
    border-radius: 5px;
    background-color: $color-white;
    cursor: pointer;

    &:hover,
    &:focus-visible {
      border-color: #0075FF;
      outline: none;
    }

    &:disabled {
      color: $color-dot-grey;
      background-color: #F9F9F9;
    }
  }

  &__list {
    position: absolute;
    top: 100%;
    // bottom: 10px;
    z-index: 1;

    width: 100%;
    max-height: 190px;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    list-style: none;

    background-color: #ffffff;
    border: 1px solid #0075FF;
    border-top: 1px solid #A1A1A1;
    border-radius: 0 0 5px 5px;
    // transform: translateY(100%);
    visibility: hidden;
    opacity: 0;

    transition: visibility 0.3s ease, opacity 0.3s ease;

    @include mobile {
      max-height: 80px;
    }
  }

  &__item {
    position: relative;
    padding: 7px 30px 7px 10px;
    color: #000;

    outline: none;
    cursor: pointer;

    transition: background-color 0.3s ease;

    &[aria-selected="true"] {
      font-weight: 700;

      &::after {
        @include pseudo();

        top: 14px;
        right: 10px;
        width: 13px;
        height: 10px;
        background-image: url("../../../assets/pictures/stack/stack.svg#check");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &:hover,
    &:focus-visible {
      background: #f3faff;
    }
  }

  &__error {
    position: absolute;
    bottom: -15px;
    left: 0;

    color: #ff1553;

    opacity: 0;

    transition: opacity 0.1s ease;
  }

  &__text {
    font-family: $font-main;
    color: $color-dot-dark;
    flex-shrink: 0;
    width: 80%;
    overflow: hidden;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    opacity: 0;

    transition: opacity 0.3s ease 0.1s;

    br {
      display: none;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-left: auto;

    mask-image: url("../../../assets/pictures/stack/stack.svg#arrow-select");
    mask-repeat: no-repeat;
    mask-size: 24px 24px;
    background-color: $color-dot-grey;

    transition: transform 0.3s ease;
  }

  &.is-disabled {
    pointer-events: none;

    .custom-select__list {
      display: none;
    }

    .custom-select__button {
      color: $color-dot-grey;
      background: #f9f9f9;
    }

    .custom-select__text {
      color: $color-dot-grey;
    }
  }

  // &.open-up {
  //   .custom-select__list {
  //     bottom: auto;
  //     top: 0;
  //     transform: translateY(calc(-100% + 10px));
  //   }
  // }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg);
    }

    .custom-select__button {
      border-radius: 5px 5px 0 0;
      border-color: #0075FF;
      border-bottom-color: transparent;
    }

  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__placeholder {
      opacity: 0;
    }

    .custom-select__icon {
      background-color: $color-dot-blue;
    }

    .custom-select__button {
      border-color: $color-dot-blue;
      background: #F3FAFF;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      border: 2px solid #ff1553;

      &:focus,
      &:hover {
        border: 2px solid rgba(#ff1553, 0.5);
      }
    }
  }

  /* &.is-valid {
    .custom-select__button {
      border: 2px solid $color-eucalyptus;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-eucalyptus, 0.5);
      }
    }
  } */


  &.slide-up.not-empty,
  &.slide-up.is-open {

    .custom-select__text {
      opacity: 1;
    }
  }
}
