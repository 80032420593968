@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.directions {
  padding: 50px 0;

  @include tablet {
    padding-bottom: 40px;
  }

  @include mobile {
    padding-top: 30px;
    padding-bottom: 10px;
  }
}

.directions__title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;

  @include tablet {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.directions__list {
  @include list;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  margin-right: 10px;

  @include tablet {
    grid-template-columns: auto;
    grid-auto-flow: column;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.directions__container {
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.directions__wrapper {
  width: 100%;

  @include tablet {
    overflow-x: auto;
    padding-bottom: 10px;
  }
}

.directions__item {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  border-right: 1px solid #D9D9D9;
  min-width: 210px;

  &:last-child {
    padding-left: 0;
    border-right: none;
  }

  svg {
    path {
      stroke: var(--primary-btn-color);
    }
    rect {
      stroke: var(--primary-btn-color);
    }
  }

  span {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 5px;
    color: $color-dot-grey;
    font-size: 10px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  p {
    font-family: $font-secondary;
    font-size: 24px;
    line-height: 1.33;
    font-weight: 500;
    margin: 0;
  }

  @include tablet {
    padding-right: 20px;
  }

  @include mobile {
    min-width: 160px;

    p {
      font-size: 18px;
    }
  }
}


