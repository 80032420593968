@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.try-group {
  padding: 50px 0;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.try-group__container {
  @include mobile {
    padding-left: 37px;
    padding-right: 37px;
  }
}

.survey-video {
  @include mobile {
    display: none;
  }
}

.try-group__inner {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/images/backgrounds/bg-color-1@2x.png");
  border-radius: 999rem;

  @include mobile {
    padding: 136px 36px;
    text-align: center;
    background-position: top 0 left 44%;
  }
}

.try-group__content {
  position: relative;
  z-index: 1;
}

.try-group__title {
  margin-top: 0;
  margin-bottom: 10px;
}

.try-group__subtitle {
  max-width: 475px;
  margin: 0 auto 10px auto;
}

.try-group__link {
  min-width: 235px;
  background-color: var(--primary-btn-color);
  color: var(--white-color);

  @include mobile {
    width: 100%;
  }

  &:hover {
    background-color: var(--primary-btn-hover-color);
  }
}
