.innerInput {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Inter, sans-serif;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  border-radius: 6px;
  transition: all 0.2s;
  border: 1px solid #d9d9d9;
  height: 38px;

  &:hover,
  &:focus {
    border: 1px solid var(--primary-btn-color);
  }
}

.borderSuffix {
  border-radius: 6px 0 0 6px;
  border-right-color: transparent;
  height: 38px;
}


.disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  &:hover,
  &:focus {
    border: 1px solid #d9d9d9;
    cursor: not-allowed;
  }
}