@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.next {
  padding: 50px 0;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.next__container {
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.next__title {
  margin-top: 0;
  margin-bottom: 20px;

  @include tablet {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}

.next__list-wrapper {
  @include tablet {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
  }

  @include mobile {
    padding-bottom: 10px;
  }
}

.next__list {
  @include list();

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-left: -20px;
  margin-right: -20px;

  @include tablet {
    margin: 0;
  }
}

.next__item {
  padding: 0 20px;
  border-right: 1px solid #D9D9D9;

  &:last-child {
    border-right: none;
  }

  span {
    display: block;
    color: $color-dot-grey;
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    margin-bottom: 12px;
  }

  p {
    color: $color-black;
    margin: 0;
  }

  @include tablet {
    width: 194px;
  }

  @include mobile {
    width: 175px;
    padding-left: 10px;
    padding-right: 10px;
  }
}


