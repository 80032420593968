@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.cases {
  .container {
    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.cases__inner {
  position: relative;
  background-color: #282F39;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;

  @include mobile {
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.cases__title {
  position: absolute;
  left: 48px;
  top: 50px;
  color: $color-white;
  margin: 0;

  @include tablet {
    position: static;
    width: 730px;
    max-width: 800px;
    margin: 0 auto 30px auto;

    br {
      display: none;
    }
  }

  @include mobile {
    position: absolute;
    top: 20px;
    left: 10px;
    width: auto;
    // padding: 0 10px;
    margin-bottom: 10px;
  }
}

.cases__slider {
  .swiper-button-prev {
    width: 48px;
    height: 48px;

    &::after {
      content: "";
      width: 48px;
      height: 48px;

      @include icon-bg();

      background-image: url("../../../assets/pictures/stack/stack.svg#arrow-slider");
      transform: rotate(-180deg);
    }

    @include mobile {
      top: 10px;
      right: 48px;
      left: auto;
      margin: 0;
    }
  }

  .swiper-button-next {
    width: 48px;
    height: 48px;

    &::after {
      content: "";
      width: 48px;
      height: 48px;

      @include icon-bg();

      background-image: url("../../../assets/pictures/stack/stack.svg#arrow-slider");
    }

    @include mobile {
      top: 10px;
      right: 0;
      margin: 0;
    }
  }

  @include mobile {
    padding-top: 70px;
  }
}

.cases__item {
  display: flex;
}

.cases__item-inner {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  max-width: 800px;
  margin: 0 auto;

  @include mobile {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
  }
}

.cases__video-box {
  grid-row: 1 / 3;
  text-align: center;

  @include mobile {
    grid-row: 1 / 2;
    text-align: left;
  }
}

.cases__video-time {
  display: block;
  color: $color-dot-grey;
  font-size: 12px;
  line-height: 1.5;
  margin-top: 10px;

  &--desktop {
    @include mobile {
      display: none;
    }
  }

  &--mobile {
    display: none;

    @include mobile {
      display: block;
    }
  }
}

.cases__video-wrap {
  position: relative;
  width: max-content;
}

.cases__video-image {
  width: 260px;
  height: 425px;
  object-fit: cover;
  border-radius: 20px;

  @include mobile {
    width: 90px;
    height: 127px;
    border-radius: 6px;
  }
}

.cases__video-btn {
  @include mobile {
    transform: translate(-50%, -50%) scale(0.5);
  }
}

.cases__content {
  // max-width: 420px;
  padding-left: 50px;

  span {
    display: block;
    width: 100%;
    color: $color-dot-grey;
    font-size: 10px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  @include mobile {
    grid-column: 1 / 3;
    padding-top: 20px;
    padding-left: 0;
  }
}

.cases__content-title-wrap {
  padding-left: 50px;

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
  }
}

.cases__content-title {
  color: $color-white;
  margin-top: 0;
  margin-bottom: 20px;

  @include mobile {
    max-width: 230px;
  }
}

.cases__path-inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 10px;
  margin-bottom: 40px;

  @include mobile {
    grid-template-columns: 1fr;
    margin-right: 10px;
    margin-bottom: 25px;
  }
}

.cases__path-step {
  color: $color-white;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 0;

  &--one {
    position: relative;
    color: $color-dot-grey;
    padding-right: 20px;

    &::before {
      @include pseudo();

      left: 40px;
      top: 19px;
      width: calc(100% - 50px);
      height: 2px;
      background-image: linear-gradient(270deg, #3C00FF 16.15%, #48565F 93.79%);

      @include mobile {
        top: 40px;
        left: 19px;
        width: 2px;
        height: calc(100% - 49px);
        background-image: linear-gradient(0deg, #3C00FF 16.15%, #48565F 93.79%);
      }
    }

    @include mobile {
      padding-right: 0;
      padding-bottom: 20px;
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;

    @include mobile {
      margin-top: 0;
    }
  }

  @include mobile {
    display: flex;
  }
}

.cases__path-circle {
  position: relative;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &--grey {
    background-color: #48565F;
  }

  &--blue {
    position: relative;
    background-color: $color-dot-blue;

    &::before {
      @include pseudo();

      top: 50%;
      left: -10px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-left: 10px solid $color-dot-blue;
      border-bottom: 6px solid transparent;

      @include mobile {
        top: -10px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
      }
    }
  }

  @include mobile {
    flex-shrink: 0;
    margin-right: 10px;
  }
}

.cases__trainings {
  @include list();

  padding: 10px 0;

  li {
    position: relative;
    display: inline-block;
    position: relative;
    color: $color-white;
    font-weight: 500;
    padding-left: 10px;
    margin-bottom: 10px;

    &::before {
      @include pseudo();

      top: 50%;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $color-dot-blue;
    }

    &::after {
      @include pseudo();

      left: 10px;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #464646;
    }
  }
}





