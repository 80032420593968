@mixin laptop {
  @media (max-width: ($laptop-width)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: ($tablet-width)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: ($mobile-width)) {
    @content;
  }
}

@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: ($laptop-width + 1)) {
    @content;
  }
}

@mixin laptop-min {
  @media (min-width: ($tablet-width + 1)) {
    @content;
  }
}

@mixin custom-size($size) {
  @media (max-width: ($size + 1)) {
    @content;
  }
}

@mixin font-face($font-family, $font-src, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
    src:
      url("#{$font-src}.woff2") format("woff2"),
      url("#{$font-src}.woff") format("woff");
  }
}

@mixin center_transform() {
  position:absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%);
}

@mixin vertical_center_transform() {
  position:absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform:translate(0, -50%);
}

@mixin button($button-bg, $button-border, $button-border-color, $button-pad, $button-size, $button-line-height, $height-button, $button-radius) {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 0;
  padding: 10px $button-pad 0 $button-pad;
  background: $button-bg;
  border: $button-border solid $button-border-color;
  font-size: $button-size;
  line-height: $button-line-height;
  height: $height-button;
  position: relative;
  @include radius($button-radius);
}

@mixin font($font-s, $font-h, $font-c) {
  font-size: $font-s;
  line-height: $font-h;
  color: $font-c;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: ""){
  content: $content;
  display: $display;
  position: $pos;
}

@mixin list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

@mixin icon-bg {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@function z($name) {
  @if index($z-indexes, $name) {
   @return (length($z-indexes) - index($z-indexes, $name)) + 100;
  } @else {
   @warn "There is no item "#{$name}" in this list; choose one of: #{$z-indexes}";
   @return null;
  }
}
$z-indexes: (
  "popup",
  "overlay",
  "header",
  "header-top",
  "header-wrapper",
);

