@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.psychologists-near {
  padding-top: 100px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/images/backgrounds/bg-1@2x.png");

  @include mobile {
    padding-top: 60px;
    padding-bottom: 60px;
    background-position: top;
    background-image: url("../../../assets/images/backgrounds/bg-1-mobile@2x.jpg");
  }
}

.psychologists-near__inner {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px;
  background-color: var(--primary-btn-color);
  border-radius: 999rem;

  @include mobile {
    padding: 20px 33px 29px;
    border-radius: 50px;
  }
}

.psychologists-near__title {
  color: $color-white;
  margin-top: 0;
  margin-bottom: 10px;
}

.psychologists-near__subtitle {
  max-width: 330px;
  color: $color-white;
  margin-top: 0;
  margin-bottom: 10px;
  margin: 0 auto 10px auto;

  @include mobile {
    padding: 0 34px;
  }
}

.psychologists-near__link {
  @include mobile {
    width: 100%;
    padding: 15px;
  }
}









