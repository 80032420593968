@import 'app/styles/breakpoints';

.root {
  margin-top: -60px;
  position: relative;
  padding-top: 20px;
  gap: 21px;
  grid-template-columns: 300px auto;

  @media screen and (max-width: map-get($breakpoints, md)) {
    margin-top: 0;
    padding: 0;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    display: grid;
  }

  //&:first-child {
  //  &:after {
  //    position: absolute;
  //    content: '';
  //    top: 0;
  //    width: 100%;
  //    height: 1px;
  //    background-color: #E8E8E8;
  //    color: #E8E8E8;
  //  }
  //
  //  @media screen and (max-width: map-get($breakpoints, sm)) {
  //    margin-top: -30px;
  //    display: flex;
  //    flex-direction: column;
  //  }
  //}
  //
  //&:before {
  //  position: absolute;
  //  content: '';
  //  bottom: 0;
  //  width: 100%;
  //  height: 1px;
  //  background-color: #E8E8E8;
  //  color: #E8E8E8;
  //}
}
