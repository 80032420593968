@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.form-checkbox {
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }

  label {
    position: relative;
    display: inline-block;
    padding: 2px 0 2px 27px;
    font-size: 12px;
    line-height: 1.5;
    color: $color-dot-grey;
    cursor: pointer;

    &::before {
      @include pseudo();

      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid $color-dot-grey;
    }

    &::after {
      @include pseudo();

      display: none;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-image: url("../../../assets/pictures/stack/stack.svg#checkbox");
      background-position: left 5px center;
      background-size: 13px 10px;
      background-repeat: no-repeat;
    }

    &:hover {
      color: $color-dot-dark;

      &::before {
        border-color: $color-dot-blue
      }
    }
  }

  input:checked + label {
    color: $color-dot-dark;

    &::before {
      border-color: $color-dot-blue;
    }

    &::after {
      display: block;
    }
  }
}
