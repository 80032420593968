@import 'app/styles/global/mixins'; // В стилях нам необходимо импортровать миксины и переменные, и все что здесь используется
@import 'app/styles/global/variables';

.specialist-meeting {
  padding-top: 25px;
  padding-bottom: 127px;

  @include mobile {
    padding-bottom: 60px;
  }
}

.specialist-meeting__container {
  display: flex;
  justify-content: space-between;

  @include tablet {
    display: block;
  }
}

.specialist-meeting__image {
  max-width: 597px;

  @include tablet {
    margin-left: auto;
    margin-right: auto;
  }
}

.specialist-meeting__content {
  max-width: 495px;

  @include tablet {
    margin-bottom: 20px;
  }
}

.specialist-meeting__title {
  margin-top: 0;
  margin-bottom: 20px;

  @include mobile {
    margin-bottom: 10px;
  }
}

.specialist-meeting__sessionImage {
  height: auto;
}
