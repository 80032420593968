@import '../../../app/styles/breakpoints';

.root {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.modal {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    margin: 10px;
    width: 100%;
    top: 5%;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    min-width: 50%;
    margin: 0 20%;
  }

  min-width: 35%;
  max-width: 35%;
}

.description {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 30px;
  font-size: 14px;
}

.buttonsWrapper{
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.primaryBtn {
  padding: 15px 37px;
  height: 49px;
}

.infoBtn {
  padding: 15px 24px;
  height: 49px;
}
