@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.form {
  padding-top: 50px;
  padding-bottom: 50px;

  .custom-select {
    font-size: 18px;

    button {
      font-size: 18px;
    }
  }

  @include mobile {
    padding-top: 30px;
    padding-bottom: 36px;
    max-width: 440px;
  }
}

.form__label {
  display: block;
  width: 160px;
  padding-top: 13px;
  padding-right: 20px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-dot-dark;

  @include mobile {
    margin-bottom: 5px;
    padding-right: 0;
    padding-top: 0;
    font-size: 8px;
  }
}

.form__row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;

  @include mobile {
    display: block;
    margin-bottom: 20px;
  }

  &--with-btn {
    margin-bottom: 19px;
  }

  &--header {
    align-items: center;

    .form__label {
      padding-top: 0;
    }
  }

  &--payment {
    .form__label {
      padding-top: 1px;
    }

    .form__prompt {
      align-items: flex-end;
    }

    @include tablet {
      .form__prompt {
        margin-left: 27px;
      }
    }

    @include mobile {
      .form__radio-inner {
        max-width: 330px;
      }
    }
  }

  &--consent {
    .form__label {
      padding-top: 5px;
    }
  }

  &--footer {
    align-items: center;
    padding: 15px 18px;
    margin: 0 -18px;
    border-radius: 999rem;
    background-color: #fffde9;

    .form__label {
      padding-top: 0;
    }

    .form__row-inner {
      align-items: center;
      flex-grow: 1;
      max-width: 600px;
    }

    .form__prompt {
      width: 250px;
      color: $color-dot-dark;
    }

    @include mobile {
      margin: 0;
      padding: 20px 12px;
      border-radius: 20px;
      text-align: center;

      .form__label {
        width: 100%;
        margin-bottom: 10px;
      }

      .form__row-inner {
        display: flex;
        flex-direction: column;
      }

      .form__prompt {
        width: auto;
        max-width: none;
        align-self: center;
      }
    }
  }

  &--course {
    align-items: flex-start;

    .form__label {
      padding-top: 5px;
    }

    .form__field {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.33;
      color: $color-dot-dark;

      p {
        margin: 0;
      }
    }
  }
}

.form__row-inner {
  display: flex;
  align-items: flex-start;

  @include tablet {
    display: block;
  }
}

.form__field {
  width: 330px;

  &--row {
    display: flex;
    flex-wrap: wrap;

    .form__field-wrap {
      flex-grow: 1;
    }

    .form__field-wrap--date {
      margin-right: 20px;
    }
  }

  @include mobile {
    width: 100%;
  }
}

.form__prompt {
  display: flex;
  align-items: center;
  min-height: 38px;
  max-width: 250px;
  margin-left: 20px;

  @include tablet {
    margin-left: 0;
    margin-top: 10px;
    min-height: 0;
  }

  @include mobile {
    max-width: 100%;
  }
}

.form__field--specialist {
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  p {
    margin: 0 0 0 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33;
    color: $color-dot-dark;
  }
}

.form__field-wrap {
  input,
  select {
    width: 100%;
    height: 38px;
    padding: 0 10px;
    font-family: $font-main;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33;
    color: $color-dot-dark;
    border: 1px solid $color-dot-grey;
    border-radius: 5px;
    background-color: $color-white;

    &:hover,
    &:focus-visible {
      border-color: #0075FF;
      outline: none;
    }

    &:not(:placeholder-shown) {
      border-color: $color-dot-blue;
    }
  }

  select {
    padding-right: 34px;
    appearance: none;
    background-image: url("../../../assets/pictures/stack/stack.svg#arrow-select");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: top 7px right 10px;
  }
}

.form__prompt {
  font-size: 12px;
  line-height: 1.5;
  color: $color-dot-grey;

  p {
    margin: 0;
  }
}

.form__btn-resolve {
  padding: 2px 0;
  margin-top: 5px;
  font-family: inherit;
  font-size: 12px;
  line-height: 1.5;
  color: $color-dot-grey;
  border: none;
  border-bottom: 1px dashed $color-dot-grey;
  background: none;

  &:hover {
    border-color: transparent;
  }
}

.form__promo-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 8px 5px;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-dot-grey;
  border: none;
  background: rgba(255, 255, 255, 0.1);
}

.form__field-wrap--promo {
  position: relative;

  input {
    padding-right: 100px;
  }

  &:focus-within,
  &:hover {
    .form__promo-btn {
      color: $color-dot-blue;
    }
  }
}

.form__radio-box {
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet {
    display: block;
  }
}

.form__radio {
  position: relative;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 27px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33;
    color: $color-dot-grey;
    cursor: pointer;

    &::before {
      @include pseudo();

      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid $color-dot-grey;
    }

    &::after {
      @include pseudo();

      display: none;
      top: 5px;
      left: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $color-dot-blue;
    }

    &:hover {
      color: $color-dot-dark;

      &::before {
        border-color: $color-dot-blue
      }
    }
  }

  input:checked + label {
    color: $color-dot-dark;

    &::before {
      border-color: $color-dot-blue;
    }

    &::after {
      display: block;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.form__payment-shares {
  padding-left: 27px;

  span {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 1.5;
    color: $color-dot-grey;
  }
}

.form__checkbox-box {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.form__total {
  margin-right: 14px;
  font-family: $font-secondary;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  color: $color-dot-dark;

  @include mobile {
    margin-right: 0;
  }
}

.form__submit {
  flex-grow: 1;

  @include mobile {
    width: 100%;
    margin-top: 10px;
    order: 2;
  }
}
