@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: $color-dot-dark;
  opacity: 0.3;

  &.opened {
    display: block;
  }
}

.popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 101;
  width: 100%;
  max-width: 380px;
  min-height: 270px;
  transform: translate(-50%, -50%);
  background-color: $color-white;
  border-radius: 20px;
  padding: 20px;

  &--contacts {
    max-width: 460px;
  }

  &--login {
    max-width: 465px;
    padding: 40px;

    @include mobile {
      padding: 20px;
    }
  }

  &--time {
    max-width: 600px;
  }
}

.popup__times {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include tablet {
    gap: 10px;
  }
}

.popup__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.popup__title {
  font-size: 24px;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 10px;
  padding-right: 34px;

  &--login {
    font-size: 36px;
    line-height: 1;
    margin-bottom: 40px;

    @include mobile {
      font-size: 24px;
      line-height: 1.25;
      margin-bottom: 20px;
    }
  }
}

.popup__text {
  font-size: 14px;
  line-height: 1.5;
  color: $color-black;
  margin-bottom: 20px;

  &--reg {
    margin-top: 20px;
    margin-bottom: 0;
  }

  a {
    color: var(--primary-btn-color);
  }
}

.popup__btns {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.popup__btn {
  min-width: 133px;

  &--wider {
    min-width: 194px;
  }
}

.popup__noty {
  max-width: 280px;
  font-size: 12px;
  line-height: 1.5;
  color: $color-dot-grey;
  margin-top: 20px;
}

.popup__form-group {
  margin-bottom: 20px;

  &--radio {
    display: flex;
    margin-bottom: 40px;
  }
}

.popup__form-label {
  display: block;
  font-size: 10px;
  line-height: 1.2;
  color: $color-dot-grey;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 5px;
}

.popup__form-field {
  width: 100%;
  max-width: 280px;
  height: 38px;
  border: 1px solid $color-dot-grey;
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 18px;
  line-height: 1.33;
  font-weight: 500;
}

.popup__form-radio-label {
  cursor: pointer;
  margin-right: 27px;

  input[type='radio'] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }

  span {
    position: relative;
    font-size: 18px;
    line-height: 1.33;
    font-weight: 500;
    padding-left: 27px;

    &::before {
      @include pseudo();

      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid $color-dot-grey;
    }

    &::after {
      @include pseudo();

      display: none;
      top: 5px;
      left: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--primary-btn-color);
    }
  }

  &:hover {
    color: $color-dot-dark;

    span::before {
      border-color: var(--primary-btn-color);
    }
  }

  input[type='radio']:checked + span {
    color: $color-dot-dark;

    &::before {
      border-color: $color-dot-blue;
    }

    &::after {
      display: block;
    }
  }
}

.popup__times-row {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 10px;

  @include tablet {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}

.popup__times-label {
  white-space: nowrap;

  p {
    font-size: 14px;
    line-height: 1.28;
    margin-bottom: 0;
  }

  span {
    display: block;
    font-size: 12px;
    line-height: 1.5;
    color: $color-dot-grey;
  }

  @include mobile {
    display: flex;
    gap: 10px;
    width: 100%;
  }
}

.popup__times-body {
  white-space: nowrap;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, 70px);
  width: 100%;

  @include tablet {
    grid-template-columns: repeat(auto-fit, 40px);
  }
}

.popup__times-item {
  height: 32px;
  text-align: center;
  background-color: var(--primary-slot-popup);
  padding: 7px 10px;
  border: unset !important ;
  border-radius: 5px !important;
  font-size: 14px;
  line-height: 1.28;

  &:last-of-type {
    margin-right: 0;
  }

  &:disabled {
    background-color: transparent;
    color: $color-dot-grey;
  }

  &--supervision {
    height: 32px;
    text-align: center;
    padding: 7px 10px;
    border: unset !important ;
    border-radius: 5px !important;
    font-size: 14px;
    line-height: 1.28;
    color: white;
    background-color: var(--primary-slot-superVision-color);

    @include mobile {
      font-size: 12px;
      line-height: 1.5;
      padding: 7px 4px;
    }
  }

  @include mobile {
    font-size: 12px;
    line-height: 1.5;
    padding: 7px 4px;
  }

  .active {
    background-color: var(--primary-btn-color) !important;
    color: white !important;
  }
}

.popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.footer-popup {
  display: grid;
  text-align: start !important;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 30px;

  @include tablet {
    gap: 5px;
  }
}
