@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}

.tabs {
  display: flex;
  gap: 0;
  justify-content: center;

  button {
    display: flex;
    justify-content: center;
    text-transform: unset;
    font-size: 16px;
    min-width: 150px;
    padding: 5px;
    &:before {
      bottom: 0;
      top: unset;
    }
  }

  @include tablet {
    button {
      min-width: 100px;
      font-size: 14px;
    }
  }

  @include mobile {
    button {
      min-width: 75px;
    }
  }
}
