@import '../../../../../app/styles/breakpoints';
@import '../../../../../app/styles/global/variables';

.modal {
  width: 472px !important;
  @media screen and (max-width: map-get($breakpoints, sm)) {
    max-width: 347px !important;
    width: unset;
  }
}

.container {
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  padding: 1rem;
  gap: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
}

.submitButton {
  margin-top: 1rem;
  height: 50px;
  color: $color-white;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.costWrapper {
  display: flex;
  justify-content: center;
  text-wrap: auto;
  gap: 14px;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 10px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.clientBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
