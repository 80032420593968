@import 'app/styles/breakpoints';

.root {
  position: relative;
  display: grid;
  padding: 50px 120px 50px 0;
  grid-template-rows: 0.1fr 0.1fr 0.1fr 0.8fr;
  gap: 10px;

  &:after {
    position: absolute;
    content: '';
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #e8e8e8;
    color: #e8e8e8;

    @media screen and (max-width: map-get($breakpoints, md)) {
      left: unset;
      bottom: 0;
      height: 1px;
      width: 100%;
    }
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    padding: 20px 0;
  }
}

.btnWrapp {
  width: 80%;
}

.chipsBlock {
  display: flex;
  gap: 10px;
  align-content: flex-start;
  flex-wrap: wrap;
}
