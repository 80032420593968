@import 'app/styles/breakpoints';

.root {
  gap: 5px;
  margin-bottom: 10px;
  @media screen and (min-width: map-get($breakpoints, lg)) {
    padding-right: 125px;
  }
}

.darkText {
  color: var(--white-color);
}

.outline {
  position: relative;
  bottom: -5px;
  color: blue;
  background-color: whitesmoke;
  border-radius: 50px;
  padding: 0 5px;
}

.centered {
  text-align: center;
}
