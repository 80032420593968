@import 'app/styles/breakpoints';
@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.achievementsCard {
  @media screen and (min-width: map-get($breakpoints, lg)) {
    padding: 3rem;
    justify-items: center;
  }

  @include tablet {
    margin: 20px 0;
  }
}
