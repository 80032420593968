@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.experts {
  padding-top: 50px;
  padding-bottom: 50px;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.experts__container {
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.experts__heading {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-bottom: 40px;

  @include tablet {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
}

.experts__title {
  margin: 0;

  @include tablet {
    margin-bottom: 20px;
  }

  @include mobile {
    margin-bottom: 10px;
  }
}

.experts__subtitle {
  color: $color-black;
  margin: 0;
}

.experts__list-wrapper {
  padding-left: calc((100% - 1160px) / 2);
  padding-right: 20px;

  @include laptop {
    padding-left: 20px;
  }

  @include mobile {
    padding-left: 10px;
  }
}

.experts__list {
  @include list();

  display: flex;
}

.experts__item {
  width: 183px;
  flex-shrink: 0;
  margin-right: 10px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  h3 {
    font-weight: 400;
    color: $color-black;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  p {
    color: $color-dot-grey;
    font-size: 12px;
    line-height: 1.5;
    padding-right: 12px;
    margin: 0;
  }

  @include mobile {
    width: 174px;
  }
}




