@import 'app/styles/breakpoints';
@import 'app/styles/global/mixins';
@import "app/styles/global/variables";

.dateSelect {
  font-family: 'Inter', sans-serif;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 5px;
  @include tablet {
    gap: 0;
  }
}

.underText {
  border-bottom: 1px dashed var(--gray-color);
}

.btn {
  background-color: white;
  border: none;
  display: flex;

  &:hover {
    cursor: pointer;
  }
}