@import "variables";
@import "mixins";
@import "fonts";
@import "btn";

body, #root {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: $min-width;
  color: $color-text;
  font-family: $font-main;
  line-height: $base-line-height;
  font-size: $base-font-size;
  font-weight: 400;
  background-color: $base-bg;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.opened-menu {
    @include tablet {
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      position: absolute;
    }
  }

  &.opened-popup {
    overflow: hidden;
  }

  @include mobile {
    font-size: 14px;
    line-height: 1.43;
  }
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  scroll-behavior: smooth;
}

main {
  flex-grow: 1;
}

input,
textarea {
  color: $color-text;
  font-family: inherit;
  outline: none;
  border-radius: 0;
  appearance: none;
}

input[type="button"],
input[type="submit"],
button {
  font-family: inherit;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
}

a {
  color: $color-link;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    // color: $color-link-hover;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: bottom;
}

/* titles */


p {
  margin-top: 0;
}

ul {
  padding-left: 20px;
  list-style-type: disc;
}

.section-title {
  font-family: $font-secondary;
  font-size: 36px;
  line-height: 1;

  span {
    position: relative;
    font-family: $font-cent;
    font-style: italic;
    font-weight: 400;
  }

  @include mobile {
    font-size: 24px;
    line-height: 1.25;
  }
}

.section-title-big {
  font-family: $font-secondary;
  font-weight: 700;
  font-size: 64px;
  line-height: 1;
  color: #000;

  span {
    position: relative;
    font-family: $font-cent;
    font-style: italic;
    font-weight: 400;
  }

  @include mobile {
    font-size: 36px;
  }
}

.suptitle {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  color: $color-dot-grey;

  @include mobile {
    font-size: 8px;
  }
}

.container {
  min-width: $min-width;
  max-width: $max-width;
  padding: 0 $padding;
  margin: 0 auto;

  @include mobile {
    padding: 0 $padding-mobile;
  }
}

.visually-hidden,
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: z("overlay");
  display: none;

  &.open {
    display: block;
  }
}

.button-play {

}

.sitemap {
  margin-top: 50px;
  list-style: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 18px;
    margin-bottom: 10px;

    &::before {
      @include pseudo();
      left: 0;
      top: 9px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color-dot-blue;
    }
  }

  a {
    &:hover {
      color: $color-dot-blue;
    }
  }
}

.video-wrap {
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  transform: translateY(-50%);

  @include mobile {
    display: none;
  }
}

.video-item {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
}
