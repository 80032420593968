.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.addButton {
  display: flex;
  align-self: flex-start;
}

.achievement {
  display: flex;
  flex-direction: row;

}

.addButton {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: 500;
  color: blue;
  cursor: pointer;
  margin: 20px 0;
  font-size: 14px;

  .icon {
    stroke: blue;
    stroke-width: 100px;
    font-size: 18px;
  }
}

.saveButton {
  width: 100%;
  margin-top: 20px;
}
