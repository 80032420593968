$primary-btn-color: #3c00ff;
$primary-btn-hover-color: #150058;
$mint-color: #f3faff;
$gray-color: #a1a1a1;
$primary-slot-superVision-color: #7f7fff;
$primary-slot-popup: #f3faff;
$light-red: #f7f1f1;
$danger-color: #ff3834;
$light-bg: #f3faff;
$light-chip: #f3faff;
$card-dark: #101721;
$icon-bg-spec: #3c00ff;
$icon-text-spec: #fff;
$icon-arrow-spec: #3c00ff;
$bg-gradient: radial-gradient(
    21.12% 83.14% at 72.43% 44.71%,
    rgba(0, 10, 255, 0.2) 0%,
    rgba(0, 10, 255, 0) 100%
  ),
  linear-gradient(0deg, #f3faff, #f3faff),
  linear-gradient(0deg, #f8f8f8, #f8f8f8);

$secondary-btn-color: #e6f6ff;
$secondary-btn-text-color: #417cff;
$secondary-btn-hover-color: #3c00ff;
$secondary-btn-text-hover-color: #fff;
$secondary-btn-disabled-color: #797979;
$footer-bg: #101721;
$border-footer: none;
$footer-text-color: #fff;
$footer-input: #282f39;
$title-font-family: Gilroy-Bold;
$m-font-family: Inter;
$promo-font-family: 21Cent;

:root {
  --primary-btn-color: #{$primary-btn-color};
  --primary-btn-hover-color: #{$primary-btn-hover-color};
  --primary-slot-superVision-color: #{$primary-slot-superVision-color};
  --primary-slot-popup: #{$primary-slot-popup};
  --secondary-btn-color: #{$secondary-btn-color};
  --secondary-btn-hover-color: #{$secondary-btn-hover-color};
  --secondary-btn-text-color: #{$secondary-btn-text-color};
  --secondary-btn-text-hover-color: #{$secondary-btn-text-hover-color};
  --secondary-btn-disabled-color: #{$secondary-btn-disabled-color};
  --footer-text-color: #{$footer-text-color};
  --title-font-family: #{$title-font-family};
  --m-font-family: #{$m-font-family};
  --mint-color: #{$mint-color};
  --gray-color: #{$gray-color};
  --light-red-color: #{$light-red};
  --footer-bg: #{$footer-bg};
  --footer-input: #{$footer-input};
  --icon-bg-spec: #{$icon-bg-spec};
  --icon-text-spec: #{$icon-text-spec};
  --icon-arrow-spec: #{$icon-arrow-spec};
  --bg-gradient: #{$bg-gradient};
  --white-color: #fff;
  --dark-color: #000;
  --danger-color: #{$danger-color};
  --light-blue: #{$light-bg};
  --light-chip: #{$light-chip};
  --card-dark: #{$card-dark};
}
