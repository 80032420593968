@import 'app/styles/breakpoints';

.root {
  position: relative;
  overflow: hidden;
  display: flex;
  max-width: 100%;
  max-height: 400px;
  border-radius: 10px !important;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    max-height: 300px;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    align-self: center;
    max-width: 100%;
    max-height: 100%;
  }
}

.noDataLogo {
  width: auto;
}

.noAvatar {
  img {
    object-fit: contain !important;
  }
}

.antImage {
  max-height: 425px;
  max-width: 260px;
  height: max-content;
  width: max-content;
  object-fit: cover;
}

.aspectRatio916 {
  aspect-ratio: 10 / 16;
}

.antImageLogo {
  aspect-ratio: auto;
}

.darkRoot {
  min-height: 250px;
}

:global {
  .ant-image-preview-operations-operation-flipY,
  .ant-image-preview-operations-operation-flipX,
  .ant-image-preview-operations-operation-rotateLeft,
  .ant-image-preview-operations-operation-rotateRight {
    display: none !important;
  }

  .ant-image-preview-operations-operation-zoomOut {
    padding: 0 !important;
  }
}
