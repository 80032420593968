.container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.mipMarker {
  display: flex;
  height: max-content;
  gap: 4px;
  min-width: 200px;
  align-items: center;
  width: max-content;
}

.MIPLogo {
  width: 20px;
  height: 16px;
  object-fit: contain;
}

.darkModeStyles {
  color: var(--white-color);
}