@import 'app/styles/breakpoints';

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.onlineCons {
  display: grid;
  grid-template-rows: 0.1fr 0.1fr 0.8fr;
  gap: 10px;
  padding: 50px 40px 50px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    padding: 20px 0;
  }
}

.btnWrapper {
  width: 50%;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
  }
}

.btn {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
  }
}
