@import 'app/styles/global/variables';
@import 'app/styles/global/mixins';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0;
  padding: 0 3rem;

  @include tablet {
    padding: 0 20px;
  }

  @include desktop {
    display: grid;
    //grid-template-columns: 1fr 1fr;
    grid-template-columns: 650px 1fr;
    column-gap: 40px;
    grid-template-areas:
      'title .'
      'description methods';
    align-items: start;

  }
}

.specializationTitle {
  @include desktop {
    grid-area: title;
  }
}

.cardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  @include desktop {
    grid-area: methods;
  }
}

.description {
  @include desktop {
    grid-area: description;
    max-width: 660px;
  }
}

.card {
  padding: 10px 15px;
  background-color: $light-chip;
  border-radius: 30px;
}
