@import 'app/styles/breakpoints';

.workWith {
  width: 100% !important;
  max-width: 308px !important;
}

.formContainer {
  position: relative;
  margin-bottom: 1rem;
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
    color: #e8e8e8;
  }
}

.filtersContainer {
  padding-bottom: 0.5rem;
  max-width: 100%;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: none;
  }
}

.mobileFiltersContainer {
  display: none;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: grid;
    grid-template-rows: auto auto;
    gap: 10px;
    justify-items: stretch;
  }
}

.btnFilters {
  display: grid;
  border-radius: 5px;
  grid-template-columns: 0.1fr 0.9fr;
  padding: 5px 10px;
  height: unset;
  gap: 10px;
  justify-items: flex-start;
  align-items: center;
  img {
    height: unset;
  }
  color: black;
  font-family: Inter, sans-serif;
  font-weight: normal;
}

.workWithContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 1rem;
}

.dateContainer {
  display: grid;
  grid-template-columns: repeat(4, 15%);
  gap: 20px;
}

.sortSelect {
  max-width: 15rem;

  @media screen and (max-width: map-get($breakpoints, md)) {
    max-width: 100%;
  }
}

.mobileFilters {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;

  &.open {
    height: auto;
  }
}
