@import 'app/styles/global/variables';

.root {
  position: relative;
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 0 10px 0 20px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    left: 0;
    bottom: 4px;
    background-color: $color-dot-grey;
    transform: rotate(45deg);
    border-radius: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    left: 0;
    top: 4px;
    background-color: $color-dot-grey;
    transform: rotate(-45deg);
    border-radius: 10px;
  }
}