@import 'app/styles/breakpoints';

.root {
  display: grid;
  gap: 5px;
  margin-top: 10px;
}

.darkText {
  color: var(--white-color);
}

.specializationTypography {
  color: var(--gray-color);
}
