@import 'app/styles/global/mixins';
@import "app/styles/global/variables";

.root {
  display: grid;
  grid-template-rows: 0.2fr 0.8fr;
  gap: 20px;
  padding: 0 50px;

  @include mobile() {
    padding: 0 20px;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}