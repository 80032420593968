@import 'app/styles/breakpoints';
@import 'app/styles/global/variables';
@import 'app/styles/global/mixins';

.root {
  border-radius: 20px;
  min-width: 400px;
  min-height: 70px;
  border-width: 2px;
  box-shadow: 0 0 8px 4px $color-gray-light;

  @media screen and (max-width: map-get($breakpoints, md)) {
    //Минимальный размер ширины карточки для мобильных устройств
    min-width: 350px;
  }

  &:hover {
    box-shadow: 0 0 12px 0 $color-link-hover;
  }

  :global {
    .ant-card-body {
      padding-top: 5px;
      padding-left: 12px;
    }
  }
}