@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.form {
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding: 40px 60px;
  width: 452px;

  @include tablet () {
    padding: 40px 30px;
    max-width: 347px;
    width: unset;
  }
  @include mobile () {
    padding: 40px 30px;
    max-width: 347px;
    width: unset;
  }
}

.formTitle {
  width: 100%;

  @include tablet () {
    text-align: start;
  }
  @include mobile () {
    text-align: start;
  }
}

.input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.itemWrapper {
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.error {
  color: red;
}

.fioWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.btnWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
}

.textInBtn {
  color: white;
  padding: 10px 30px;
  font-weight: 600;
}

.btnLogin {
  height: unset;
  width: 100%;
}

.btnSSO {
  height: unset;
  width: 100%;

  .textInBtn {
    color: var(--secondary-btn-text-color);
  }
}

.radioGroup {
  display: flex;
  justify-content: space-between;
}

.radioBtnText {
  font-weight: 400;
}

.btnText {
  color: var(--primary-btn-color);
  font-size: 14px;
}

.btn {
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.textWrapper {
  display: flex;
  gap: 5px;
}

.accountCheckText {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.formFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.textHelp {
  color: var(--gray-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.textHelpBtn {
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }

  color: var(--gray-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline;
}

.captchaContainer {
  width: 100%;

  @include tablet () {
    iframe {
      width: 287px !important;
    }
  }

  @include mobile () {
    iframe {
      width: 287px !important;
    }
  }
}
.sendAgainBtn {
  padding: 0;
}
.sendAgainBtnText {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  border-bottom: 2px dotted;
  color: var(--gray-color);
}
.sendAgainBtnText:hover {
  color: var(--secondary-btn-text-color);
}
