@import 'app/styles/breakpoints';

.root {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.modal {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    margin: 10px;
    width: 100%;
    top: 5%;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    min-width: 50%;
    margin: 0 20%;
  }

  width: 40%;
  min-width: 40%;
  max-width: 50%;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
}

.borderless {
  border-bottom: var(--primary-btn-color) solid 1px;
  border-radius: 0;
  padding: 5px 0;

  &::placeholder {
    color: var(--gray-color);
    opacity: 1;
  }
}

.maskBorderless {
  border: none;
  border-bottom: var(--primary-btn-color) solid 1px;
  border-radius: 0;
  padding: 5px 0;

  &:focus,
  &:hover {
    border: none;
    border-bottom: var(--primary-btn-color) solid 1px;
    border-radius: 0;
  }
}

.input::placeholder {
  color: var(--gray-color);
  opacity: 1;
}

.button {
  padding: 25px;
  font-size: 12px;
}

.error {
  color: red;
}

.hidden {
  display: none;
}
