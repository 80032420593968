@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  :global(.ant-radio) {
    @include mobile() {
      width: 20px !important;
      height: 20px !important;
    }
  }

  :global(.ant-radio-inner::after) {
    background-color: var(--primary-btn-color) !important;
    width: 26px !important;
    height: 26px !important;
    margin-block-start: -13px !important;
    margin-inline-start: -13px !important;

    @include mobile() {
      width: 20px !important;
      height: 20px !important;
      margin-block-start: -10px !important;
      margin-inline-start: -10px !important;
    }
  }

  :global(.ant-radio-inner) {

    @include mobile() {
      width: 18px !important;
      height: 18px !important;
    }
  }

  :global(.ant-radio-checked .ant-radio-inner) {
    background-color: white !important;
    border-width: 2px !important;
  }
}
