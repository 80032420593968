@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.card-specialist {
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 180px 1fr 250px 156px;
  gap: 20px;

  @include tablet {
    grid-template-columns: 180px 1fr;
    grid-template-rows: 1fr;
  }

  @include mobile {
    grid-template-columns: 90px 1fr;
    gap: 20px 10px;
  }
}

.card-specialist__image {
  position: relative;
  width: 180px;
  height: 254px;
  border-radius: 10px;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 4;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  @include tablet {
    grid-row: 1 / 3;
  }

  @include mobile {
    width: 90px;
    height: 127px;
    border-radius: 5px;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}

.card-specialist__play {
  @include mobile {
    transform: translate(-50%,-50%) scale(0.53);
  }
}

.card-specialist__buttons {
  width: 156px;
  grid-column: 4 / 5;
  grid-row: 1 / 2;

  @include tablet {
    grid-column: 1 / -1;
    grid-row: 5 / 6;
    display: flex;
    align-items: flex-start;
    width: auto;
  }
}

.card-specialist__button {
  display: block;
  margin-bottom: 13px;

  @include tablet {
    margin-bottom: 0;
    margin-right: 13px;
    width: 171px;
    padding: 15px 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-specialist__name {
  max-width: 280px;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 1.3;
  color: $color-black;

  &--super::after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 8px;
    border-radius: 50%;
    background-color: $color-dot-blue;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../assets/pictures/stack/stack.svg#s");
    background-size: 8px 10px;
  }

  @include mobile {
    max-width: 200px;
    font-size: 24px;
    line-height: 1.25;
  }
}

.card-specialist__subtitle {
  display: block;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-dot-grey;

  @include mobile {
    font-size: 8px;
    line-height: 1.25;
  }
}

.card-specialist__specialization {
  grid-column: 2 / 5;
  grid-row: 2 / 3;

  p {
    margin: 0;
  }

  @include tablet {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
  }
}

.card-specialist__desc-list {
  @include list();

  display: flex;
  gap: 20px;
  padding-right: 10px;
  margin-left: auto;

  p {
    margin: 0;
  }

  @include tablet {
    margin-left: 0;
  }
}

.card-specialist__slots-box {
  grid-column: 2 / 5;
  grid-row: 3 / 4;

  @include tablet {
    grid-column: 1 / -1;
    grid-row: 4 / 5;
  }
}

.card-specialist__suptitle {
  display: block;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-dot-grey;

  @include mobile {
    font-size: 8px;
    line-height: 1.25;
  }
}

.card-specialist__slots-box {
  @include mobile {
    width: 100%;
    overflow-x: auto;
  }
}

.card-specialist__slots-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @include mobile {
    flex-wrap: nowrap;
  }
}

.card-specialist__slots-wrap {
  display: flex;
}

.card-specialist__slot {
  display: inline-block;
  padding: 6px 9px;
  margin-right: 10px;
  font-size: 14px;
  line-height: 1.29;
  color: $color-dot-dark;
  text-align: center;
  border-radius: 99rem;
  border: 1px solid $color-dot-blue;
  background: transparent;

  @include mobile {
    font-size: 12px;
    line-height: 1.67;
  }
}

.card-specialist__slots-all {
  flex-shrink: 0;
}

.card-specialist--dark {
  padding: 50px;
  color: $color-white;
  border-radius: 20px;
  background-color: #101721;

  .card-specialist__name {
    font-size: 48px;
    line-height: 1.04;
    color: #fff;
  }

  .card-specialist__slot {
    color: $color-white;
  }

  @include laptop {
    padding: 50px 40px;

    .card-specialist__name {
      font-size: 36px;
    }
  }

  @include tablet {
    padding: 40px 30px;
  }

  @include mobile {
    padding: 20px 10px;

    .card-specialist__name {
      font-size: 24px;
      line-height: 1.25;
    }
  }
}

.card-specialist__thumbs {
  width: 170px;
  margin-left: auto;
  margin-right: auto;

  @include mobile {
    display: none;
  }
}

.card-specialist__thumbs-image {
  opacity: 0.5;
  cursor: pointer;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }

  &.swiper-slide-thumb-active {
    opacity: 1;
  }
}

.card-specialist__slider {
  width: 260px;
  grid-column: 1 / 2;
  grid-row: 1 / 5;

  @include tablet {
    grid-row: 1 / 3;
  }

  @include mobile {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;
  }
}

.card-specialist__slider-main {
  width: 100%;
  height: 425px;
  margin-bottom: 10px;
  border-radius: 20px;

  .card-specialist__image  {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  @include mobile {
    border-radius: 0;
    padding-left: 10px;
    padding-right: 10px;

    .card-specialist__image {
      width: 260px;
      height: 425px;
    }
  }
}

.card-specialist__session-wrapper {
  min-width: 256px;
  padding-top: 15px;
  margin-bottom: 15px;

  @include mobile {
    padding: 20px;
    border-radius: 20px;
    background: #f3faff;
  }
}

.card-specialist__session {
  @include list();

  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  margin-bottom: 14px;

  p {
    margin: 0;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 32px;
    line-height: 1;
    color: $color-dot-dark;
  }

  @include mobile {
    justify-content: center;
  }
}

.card-specialist__desc-item-content {
  overflow: hidden;
  padding-right: 10px;

  &.collapse {
    position: relative;
    height: 55px;

    &::after {
      content: "...";
      position: absolute;
      right: 0;
      bottom: 8px;
      display: block;
      width: 38px;
      height: 21px;
      font-weight: 700;
      font-size: 18px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #0075ff;
      text-align: center;
      border-radius: 999rem;
      background: #f3faff;
      cursor: pointer;
    }
  }

  @include mobile {
    &.collapse {
      height: 45px;

      &::after {
        bottom: 5px;
      }
    }
  }
}

.card-specialist--main {
  padding-top: 50px;
  grid-template-columns: 260px 1fr;
  grid-template-rows: repeat(3, auto) 1fr;
  column-gap: 38px;

  .card-specialist__desc {
    display: flex;
    flex-wrap: wrap;
  }

  .card-specialist__name {
    max-width: 460px;
    margin-bottom: 15px;
    font-size: 64px;
    line-height: 1.09;
  }

  .card-specialist__description {
    margin-right: auto;
    padding-right: 20px;
  }

  .card-specialist__desc-list {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 17px 37px;
    padding-right: 0;

    li:nth-child(4) {
      grid-column: 1 / 3;
    }
  }

  .card-specialist__slots-box {
    grid-column: auto;
    grid-row: auto;
  }

  @include laptop {
    .card-specialist__description {
      margin-right: auto;
    }
  }

  @include tablet {
    grid-template-rows: auto 1fr;

    .card-specialist__name {
      max-width: 280px;
      font-size: 36px;
      line-height: 1;
    }

    .card-specialist__button {
      width: 100%;
      max-width: 350px;
    }

    .card-specialist__list-wrapper {
      grid-column: 1 / 3;
    }

    .card-specialist__slots-box {
      grid-column: 1 / 3;
    }

    .card-specialist__desc {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @include mobile {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;

    .card-specialist__desc {
      display: block;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .card-specialist__name {
      margin-bottom: 20px;
    }

    .card-specialist__button {
      margin-left: auto;
      margin-right: auto;
    }

    .card-specialist__desc-list {
      grid-template-columns: min-content auto;
      gap: 20px 55px;

      li:nth-child(n + 3) {
        grid-column: 1 / 3;
      }
    }

    .card-specialist__list-wrapper {
      margin-bottom: 15px;
    }

    .card-specialist__play {
      transform: translate(-50%, -50%);
    }
  }
}

.card-specialist {
  .breadcrumbs {
    padding-top: 0;
    padding-bottom: 0;
  }

  .breadcrumbs__container {
    padding: 0;
    min-width: 0;
  }
}
