@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include mobile() {
    padding: 0 20px;
  }
}

.subtitle {
  font-weight: bold;
}

.itemWrap {
  display: grid;
  grid-template-rows: 0.2fr 0.8fr;
  gap: 10px;
}

.radioGroup {
  display: flex;
  gap: 20px;

  @include mobile() {
    flex-direction: column;
  }
}

.error {
  color: red;
}
