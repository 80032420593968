@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';
@import 'app/styles/breakpoints';

.container {
  display: flex;
  padding: 60px 20px;
  margin: auto auto 10rem auto;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: map-get($breakpoints, md)) {
    flex-direction: column;
    margin: 40px 0;
  }
}

.cardLabel {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
