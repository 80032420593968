@import 'app/styles/breakpoints';

.content {
  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 100%;
  }
}

.errorLink {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    min-height: 400px;
  }
}
