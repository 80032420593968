@import 'app/styles/breakpoints';

.typography {
  color: var(--dark-color);
  font-family: Inter, sans-serif;
  font-weight: normal;
}

.p {
  font-size: 16px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 14px;
  }
}

.h1 {
  font-family: var(--title-font-family), sans-serif;
  font-weight: bold;
  font-size: 64px;
}

.h2 {
  font-family: var(--title-font-family), sans-serif;
  font-weight: bold;
  font-size: 48px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 36px;
  }
}

.h3 {
  font-family: var(--title-font-family), sans-serif;
  font-weight: bold;
  font-size: 32px;
}

.title {
  font-family: var(--title-font-family), sans-serif;
  font-weight: bold;
  font-size: 36px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 28px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 24px;
  }
}

.subtitle {
  font-size: 10px;
  font-weight: initial;
  color: var(--gray-color);

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 8px;
  }
}

.italic {
  font-family: '21 Cent Italic', sans-serif;
  font-style: italic;
  font-weight: normal;
}

.description {
  font-size: 10px;
  color: var(--gray-color);
  font-weight: 500;

  @media screen and (max-width: map-get($breakpoints, xs)) {
    font-size: 8px;
  }
}

.textM {
  font-size: 24px;
  line-height: 36px;
  font-family: var(--m-font-family), sans-serif;
  font-weight: 500;
}

.bold {
  font-size: 21px;
  font-family: var(--m-font-family), sans-serif;
  line-height: 24px;
  font-weight: 500;
}
