.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(249 249 249);
  width: 100%;
  height: 100%;
}
