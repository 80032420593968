@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.statistics {
  padding-top: 40px;
  padding-bottom: 40px;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.about__project-image {
  margin-top: 5px;
}

.statistics__list {
  @include list;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 40px;
  }

  @include mobile {
    gap: 10px 25px;
  }
}

.statistics__item {
  padding-top: 20px;
  padding-bottom: 20px;

  p {
    margin: 0;
  }

  img {
    object-fit: initial;
    width: 100px;
    height: 37.48px;
  }

  @include mobile {
    padding-top: 0;
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
  }
}

.statistics__number {
  font-family: $font-secondary;
  font-size: 36px;
  line-height: 1;
  color: var(--primary-btn-color);
  font-weight: 700;

  @include mobile {
    font-size: 20px;
    line-height: 28px;
  }
}
