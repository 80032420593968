@import '../../../app/styles/breakpoints';

.container {
  margin-top: -60px;
  position: relative;
  padding-top: 20px;
  gap: 21px;
  grid-template-columns: 300px auto;

  @media screen and (max-width: map-get($breakpoints, md)) {
    margin-top: 0;
    padding: 0;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    display: grid;
  }
}

.specInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.specialistInfoWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
    flex-direction: column;
  }
}

.nameBlock {
  display: flex;
  flex-direction: column;


}

.specName {
  display: flex;
  flex-direction: column;
}

.secondName,
.firstName {
  font-size: 64px;
  vertical-align: bottom;
  line-height: 1.1;

  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 40px;
  }
  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 36px;
  }
}

.secondName {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    margin-bottom: 4%;
  }
}