@import 'app/styles/breakpoints';
@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 30px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    gap: 20px;
    padding: 0 20px;
  }
}

.gridBlock {
  display: grid;
  justify-content: center;
  align-items: start;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: 0.07fr 1fr;
}

.expandButton {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-self: flex-end;
}

.expWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  button {
    padding: 8px 10px;
    height: unset;
  }
}

.expandableCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardContent {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-rows: 0.2fr auto;
    grid-template-columns: auto;
  }
}

.btnClose {
  max-width: 150px;
}

.wrapperData {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrappedWorkMethodsData {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.year {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 10px;
  color: var(--primary-btn-color);
}

.workMethod {
  display: grid;
  grid-template-areas: '. description icon';
  grid-template-columns: 0.07fr 1fr 20px;
  padding-left: 12px;

  @include tablet {
    padding-left: initial;
    display: flex;
    justify-content: space-between;
  }
}

.headerText {
  font-size: 20px !important;
  line-height: 28px !important;
}

.text {
  grid-area: description;
}
.infoButton {
  grid-area: icon;

  font-size: 20px;
  color: var(--gray-color);
}
