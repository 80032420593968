.ant-upload-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 102px);
  align-items: start;
  grid-template-rows: repeat(auto-fit, 102px);
  gap: 5px;

  &:before {
    display: none !important;
  }
}

.ant-image-preview-img {
  height: auto;
}

.upload-container {
  display: flex;
}
