@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.meetings {
  padding: 50px 0;
  margin: 10px;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.meetings__inner {
  display: grid;
  gap: 20px;
}

.meetings__title {
  margin-top: 0;
  margin-bottom: 10px;

  span {
    display: inline-block;

    &::before {
      @include pseudo;

      left: 2px;
      bottom: -10px;
      width: 280px;
      height: 13px;
      mask-image: url('../../../assets/pictures/stack/stack.svg#title-line-straight2');
      background-color: var(--primary-btn-color);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @include mobile {
    span {
      &::before {
        display: none;
      }
    }
  }
}

.meetings__item {
  display: flex;
  flex-direction: column;
  background-color: #f3faff;
  border-radius: 20px;
  padding: 16px 20px 20px;

  h3 {
    margin-top: 0;
    margin-bottom: 10px;
    padding-top: 4px;
    font-size: 12px;
    line-height: 1.33;
    text-transform: uppercase;
    letter-spacing: 2px;

    b {
      display: inline-block;
      max-width: 184px;
    }
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
  }

  @include tablet {
    position: relative;
    max-width: 230px;

    padding-top: 64px;

    &:not(:last-child)::after {
      @include pseudo();

      top: 12em;
      left: 11.5em;
      width: 20px;
      height: 20px;
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &::before {
      @include pseudo();

      top: 20px;
      left: 20px;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: var(--primary-btn-color);
    }
  }

  @include mobile {
    padding: 44px 10px 10px;
    width: 191px;

    &:not(:last-child)::after {
      @include pseudo();

      top: 125px;
      left: 190px;
      width: 16px;
      height: 16px;
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &::before {
      top: 10px;
      left: 10px;
      width: 24px;
      height: 24px;
    }

    p {
      font-size: 12px;
      line-height: 1.5;
    }
  }
}

.meetings__subtitle {
  margin-top: 0;
  margin-bottom: 20px;
}

.meetings__circle {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-color: var(--primary-btn-color);
  }

  @include tablet {
    display: none;
  }
}

.meetings__container {
  padding: 0;
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.meetings__wrapper {
  @include tablet {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
  }

  @include mobile {
    padding-bottom: 10px;
  }
}

.meetings__inner {
  @include tablet {
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.meetings__heading {
  @include tablet {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.meetings--group {
  @include laptop-min {
    .meetings__inner {
    }

    .meetings__heading {
      background-color: transparent;
      padding: 0 0 28px 0;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .meetings__subtitle {
      max-width: 405px;
    }

    .meetings__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:nth-child(2) {
        flex-direction: row;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;

        h3 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 50px;
          margin-bottom: 0;
        }

        .meetings__circle {
          margin-top: 50px;
        }
      }

      &:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 4;
        min-width: 450px;

        h3 {
          display: flex;
          width: 100%;
          margin-bottom: 30px;
        }

        .meetings__circle {
          margin-left: 20px;
          flex-grow: 1;
        }
      }

      &:nth-child(4) {
        h3 {
          display: flex;
          justify-content: space-between;
        }

        .meetings__circle {
          margin-left: 35px;
          flex-grow: 1;
          max-width: 78px;
        }
      }

      &:nth-child(5) {
        h3 {
          display: flex;
          justify-content: space-between;
        }

        .meetings__circle {
          margin-left: 10px;
          flex-grow: 1;
          max-width: 100px;
        }
      }
    }
  }

  @include mobile {
    padding-bottom: 40px;
  }
}

.meetings--single {
  @include laptop-min {
    .meetings__heading {
      text-align: center;
    }

    .meetings__inner {
      grid-template-columns: 25.8% 64px 23.8% 64px 1fr;
    }

    .meetings__item {
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 2;

        .meetings__circle {
          max-width: 171px;
          margin-top: auto;
        }

        p {
          margin-bottom: 13px;
        }
      }

      &:nth-child(2) {
        grid-column-start: 2;
        grid-column-end: 4;

        .meetings__circle {
          width: 106px;
          margin-top: auto;
          margin-left: auto;
        }

        p {
          margin-bottom: 13px;
        }
      }

      &:nth-child(3) {
        grid-column-start: 4;
        grid-column-end: 6;

        .meetings__circle {
          max-width: 172px;
          margin-top: auto;
        }

        p {
          margin-bottom: 13px;
        }
      }

      &:nth-child(4) {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 3;
        padding-right: 100px;

        .meetings__circle {
          position: absolute;
          right: 20px;
          bottom: 20px;
          width: 68px;
        }

        p {
          max-width: 205px;
        }
      }

      &:nth-child(5) {
        position: relative;
        grid-column-start: 3;
        grid-column-end: 5;
        padding-left: 158px;

        .meetings__circle {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 106px;
        }

        p {
          max-width: 152px;
        }
      }

      &:nth-child(6) {
        position: relative;
        grid-column-start: 5;
        grid-column-end: 6;

        .meetings__circle {
          position: absolute;
          right: 20px;
          top: 20px;
          width: 32px;
        }
      }
    }
  }
}
