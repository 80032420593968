@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.container {
  background-color: var(--card-dark);
  border-radius: 20px;
  padding: 40px 50px;
  gap: 20px;
  display: flex;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperImage {
  width: 180px;
  height: 254px;
  display: flex;
  justify-content: center;
}

.infoWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nameBlock {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.nameBlockDark {
  color: var(--white-color);
}

.wrapperNavigation {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  @include laptop {
    flex-direction: column;
  }
}

.darkWrapperBtns {
  width: auto;
}

