@import 'app/styles/breakpoints';
@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapperLogos {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin: 5rem 0 0 0;

  @include tablet() {
    flex-direction: column;
    align-items: center;
  }
}

.mipLogo {
  height: auto;
  width: max-content;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
  gap: 50px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    margin-top: 50%;
  }
}
