.root {
  display: flex;
  justify-content: center;
  z-index: 10;
  height: 25px;
  .btn {
    display: none;
  }

  &:hover {
    .btn {
      display: inline-block;
    }
  }
}
