@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.testing__inner {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 760px;
  background-color: $color-white;
  border-radius: 20px;
}

.tabs {
  padding: 50px;

  @include mobile {
    padding: 50px 10px;
  }
}

.formWrapper {
  display: grid;
  grid-template-rows: 0.8fr 0.2fr;
  gap: 40px;
}

.btnWrapper {
  padding: 50px;
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column-reverse;
    gap: 5px;
    padding: 20px;
  }
}

.btnFirstWrap {
  flex-direction: row-reverse;
}

.btnNext {
  padding: 15px 90px !important;
  height: unset;

  @include mobile {
    padding: 7px 45px !important;
  }
}

.btnSubmit {
  padding: 15px 25px !important;
  height: unset;

  @include mobile {
    padding: 7px 15px !important;
  }
}

.btnPrevios {
  color: var(--primary-btn-color);
  background-color: #f3faff;
  padding: 15px 40px !important;
  height: unset;

  @include mobile {
    padding: 7px 20px !important;
  }
}
