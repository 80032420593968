@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: flex;
  background-color: rgb(249 249 249);
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.gridForm {
  position: relative;
  display: grid;
  background-color: var(--white-color);
  border-radius: 20px;
  grid-template-columns: 1fr auto;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;

  @include mobile () {
    right: 27px;
  }
}

.imgClose {
  height: 28px;
  width: 28px;
  color: var(--gray-color);
}

.form {
  position: relative;
  display: flex;
  gap: 40px;
  flex-direction: column;
  padding: 40px;
}

.itemWrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.btnWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.textInBtn {
  color: white;
  padding: 10px 30px;
  font-weight: 600;
}

.btnLogin {
  height: unset;
}

.radioGroup {
  display: flex;
  justify-content: space-between;
}

.radioBtnText {
  font-weight: 400;
}

.btnText {
  color: var(--primary-btn-color);
}

.btn {
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.textWrapper {
  display: flex;
  gap: 5px;
}
