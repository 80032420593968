@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.hero-success {
  position: relative;
  text-align: center;
  background-color: #f3f9fe;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/images/backgrounds/bg-heart@2x.jpg");
  padding: 100px 10px 68px 10px;

  @include mobile {
    padding: 60px 0;
    background-image: url("../../../assets/images/backgrounds/bg-heart-mobile@2x.jpg");
  }
}

.hero-success__inner {
  position: relative;
  z-index: 1;
}

.hero-success__image {
  width: 240px;
  height: 240px;
  border-radius: 50%;

  @include mobile {
    width: 120px;
    height: 120px;
  }
}

.hero-success__title {
  max-width: 1024px;
  margin: 20px auto;
}

.hero-success__subtitle {
  max-width: 470px;
  color: $color-black;
  margin: 0 auto;
}

