@import 'app/styles/breakpoints';

.radioButton {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  border: 1px solid var(--primary-btn-color) !important;
  border-radius: 25px !important;

  &:before {
    background: none !important;
  }

  @media screen and (max-width: map-get($breakpoints, xs)) {
    font-size: 12px;
  }
}
