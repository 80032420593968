@import "app/styles/global/mixins"; // В стилях нам необходимо импортровать миксины и переменные, и все что здесь используется
@import "app/styles/global/variables";

.specialist-theme {
  padding-top: 50px;
  padding-bottom: 25px;

  @include mobile {
    padding-top: 20px;
  }
}

.specialist-theme__title {
  margin-top: 0;
  margin-bottom: 7px;
}

.specialist-theme__slider {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  padding-left: calc((100% - 1160px)/2);
  padding-right: calc((100% - 1160px)/2);
}

.specialist-theme__list {
  @include list();

  display: flex;
}

.specialist-theme__item {
  display: inline-block;
  width: auto;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.29;
  color: $color-black;
  border-radius: 999rem;
  background-color: var(--light-chip);
}
