@import "app/styles/global/variables";
@import "app/styles/global/mixins";
@import "app/styles/breakpoints";

.root {
  background: $color-light-blue;
}

.headerText {
  line-height: 64px;
  box-sizing: border-box;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    line-height: 2.4rem;
    font-size: 2.4rem;
  }

  @media screen and (max-width: map-get($breakpoints, xs)) {
    line-height: 2.2rem;
    font-size: 2.2rem;
  }

}

.headerFragment {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    line-height: 2.4rem;
    font-size: 2.4rem;
  }

  @media screen and (max-width: map-get($breakpoints, xs)) {
    line-height: 2.2rem;
    font-size: 2.2rem;
  }

}

.content {
  display: flex;
  flex-direction: row;
  gap: 100px;
  min-width: 320px;
  max-width: 1200px;
  padding: 100px 0;
  margin: 0 auto;
  justify-content: space-between;

  @include tablet {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    padding: 0 10px;
  }
}

.heroInfo {
  width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  @include tablet {
    width: 100%;
    align-items: center;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;

}

.discountInfo {
  font-weight: 400;
  font-style: italic;
  font-size: 36px;
  line-height: 36px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    line-height: 2.1rem;
    font-size: 2.1rem;
  }

  @media screen and (max-width: map-get($breakpoints, xs)) {
    line-height: 2rem;
    font-size: 2rem;
  }
}

.details {
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    line-height: 1.4rem;
    font-size: 1.3rem;
  }

  @media screen and (max-width: map-get($breakpoints, xs)) {
    line-height: 1.3rem;
    font-size: 1.2rem;
  }
}

.copyPromoCode {
  margin-top: 10px;
  padding: 25px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.21;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
    margin-top: 0;
  }

  @include tablet {
    margin-bottom: 40px;
  }
}

.studentCard {
  box-sizing: border-box;
  width: 450px;
  height: 290px;
  object-fit: contain;
  margin: auto 0;
}