.root {
  display: flex;
  border: solid 1px;
  border-radius: 25px;
  border-color: var(--primary-btn-color);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
}