@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.emergency-contacts {
  padding-top: 100px;
  padding-bottom: 50px;

  @include mobile {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

.emergency-contacts__inner {
  display: flex;
  width: 100%;

  @include mobile {
    display: block;
  }
}

.emergency-contacts__half {
  width: 50%;
  padding: 0 20px;
  text-align: center;

  &:first-child {
    border-right: 1px solid #E8E8E8;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  span {
    display: block;
    color: $color-dot-grey;
    font-size: 10px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
  }

  @include mobile {
    width: 100%;

    &:first-child {
      border-right: none;
      margin-bottom: 30px;
    }

    span {
      font-size: 8px;
    }
  }
}

.emergency-contacts__title {
  font-family: $font-secondary;
  color: $color-dot-blue;
  font-size: 36px;
  line-height: 1;
  font-weight: 700;

  @include mobile {
    font-size: 24px;
  }
}

