@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.hero-card {
  position: relative;
  display: block;
  width: 180px;
  height: 240px;
  padding: 20px 10px 10px;
  text-align: center;
  border-radius: 20px;

  p {
    margin: 0;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    z-index: 2;
  }

  &--light {
    background-color: $color-white;
    padding-bottom: 55px;

    &::after {
      @include pseudo();

      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 24px;
      height: 24px;
      background-image: url("../../../assets/pictures/stack/stack.svg#arrow-right-grey");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &--image {
    display: flex;
    flex-direction: column;
    background-color: #000AFF;

    &::before {
      @include pseudo();
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, #000AFF 0%, rgba(0, 10, 255, 0) 52.8%);
      border-radius: 20px;
      z-index: 3;
    }

    p {
      position: relative;
      margin-top: auto;
      color: $color-white;
      z-index: 4;
    }
  }
}

.hero-card__title {
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: $font-secondary;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  text-align: center;
  color: $color-dot-grey;
}
