.root {
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 50px auto;

}

.wrapperAvatar {
  position: relative;
  width: 50px;
  height: 50px;
}

.imgAvatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 0%;
}
