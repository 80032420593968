@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.catalog-filter__title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  line-height: 1.04;
  color: #000;

  @include mobile {
    font-size: 36px;
    line-height: 1;
  }
}

.catalog-filter__form-toggle {
  display: none;

  @include mobile {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 9px 9px 9px 44px;
    font-size: 14px;
    line-height: 1.43;
    color: #000;
    text-align: left;
    background: #dff3ff;
    border: none;
    border-radius: 5px;

    &::before {
      @include pseudo();
      top: 7px;
      left: 10px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../../../assets/pictures/stack/stack.svg#filter");
    }
  }
}

.catalog-filter__header {
  @include mobile {
    position: relative;
    // display: flex;
    // align-items: center;
    margin-bottom: 20px;
  }
}

.catalog-filter__sort {
  display: flex;

  select {
    height: 24px;
    padding: 0 30px 0 5px;
    margin-left: auto;
    font-family: $font-main;
    font-weight: 400;
    font-size: 14px;
    line-height: 129%;
    color: $color-dot-dark;
    border-radius: 5px 5px 0 0;
    appearance: none;
    background-image: url("../../../assets/pictures/stack/stack.svg#arrow-select");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: top 0 right 5px;
    background-color: #f5f5f5;
    border: none;
  }

  @include mobile {
    select {
      width: 100%;
      height: 38px;
      padding-left: 44px;
      border: 1px solid $color-dot-grey;
      border-radius: 5px;
      background-color: #fff;
      background-image: url("../../../assets/pictures/stack/stack.svg#arrow-select"), url("../../../assets/pictures/stack/stack.svg#sort-bars");
      background-position: top 6px right 9px, top 6px left 9px;
    }
  }
}

.catalog-filter__wrapper {
  border-top: 1px solid #E8E8E8;
}

.catalog-filter__item {
  border-bottom: 1px solid #E8E8E8;
}

.catalog-filter__footer {
  display: flex;
  border-bottom: 1px solid #E8E8E8;

  @include tablet {
    display: block;
  }

  @include mobile {
    border: none;
  }
}

.catalog-filter__footer-item {
  width: 50%;
  padding-top: 62px;
  padding-bottom: 40px;
  padding-right: 40px;

  &--consultation {
    padding-left: 40px;
    border-left: 1px solid #E8E8E8;

    p {
      max-width: 400px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  @include tablet {
    width: 100%;
    padding-right: 0;

    &--consultation {
      padding-left: 0;
      border-left: none;
      border-top: 1px solid #E8E8E8;
    }
  }

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;

    &--parameters {
      padding-bottom: 10px;
    }
  }
}

.catalog-filter__block-title {
  margin-top: 0;
  margin-bottom: 10px;

  @include tablet {
    max-width: 400px;
  }
}

.catalog-filter__parameters-wrapper {
  display: flex;
  flex-wrap: wrap;

  button {
    position: relative;
    padding: 8px 40px 8px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-family: $font-main;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.29;
    color: $color-dot-dark;
    border-radius: 999rem;
    background-color: #e6f6ff;

    &::after {
      @include pseudo();

      top: 5px;
      right: 10px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px 18px;
      background-image: url("../../../assets/pictures/stack/stack.svg#plus");
      transform: rotate(45deg);
    }
  }

  @include mobile {
    margin-right: -10px;
  }
}

.catalog-filter__telegram {
  @include mobile {
    width: 100%;
    padding: 15px 20px;
  }
}
