@import 'app/styles/breakpoints';
@import 'app/styles/global/variables';
@import 'app/styles/global/mixins';

.content {
  width: 100%;
  display: flex;
  padding: 5% 5% 0 5%;
  justify-content: center;

  @include tablet {
    padding: 5% 3% 0 3%;
  }
}

.bg_lightBlue {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--light-blue) !important;
}

.contentContainer {
  width: 85%;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 90%;
  }
  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
  }
}

.containerFullWidth {
  width: 100%;
}

.contentUZ {
  width: 90%;
  display: flex;
  justify-content: center;
}
.filtersUZ {
  width: 35vh;
  display: flex;
  justify-content: center;
  padding-top: 25vh;
}

.paddingTopNone {
  padding: 1% 5% 0 5%;
}

.paddingXNone {
  padding: 0;
}
