@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.order {
  padding-bottom: 50px;

  @include mobile {
    padding-bottom: 0;
  }
}

.order__title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 48px;
  line-height: 1.04;
  color: $color-black;

  @include mobile {
    font-size: 36px;
    line-height: 1;
  }
}
