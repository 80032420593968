@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: flex;
  flex-direction: column;
}

.tabs_controls {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  gap: 8px;

  @include mobile {
    gap: 0;
  }
}

.tabs_control {
  display: inline-block;
  position: relative;
  background: white;
  text-align: left;
  color: #d9d9d9;
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-top: 8px;

  &::before {
    @include pseudo();

    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #d9d9d9;
    border-radius: 999rem;
  }
}

.tabs_active {
  color: var(--primary-btn-color);

  &::before {
    background-color: var(--primary-btn-color);
  }
}
