@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.breadcrumbs {
  padding-top: 50px;
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-dot-grey;

  a {
    color: $color-dot-grey;
  }

  ul {
    @include list();
  }

  li {
    position: relative;
    display: inline-block;
    margin-right: 12px;

    &::after {
      @include pseudo();

      top: 6px;
      right: -8px;
      width: 3px;
      height: 3px;
      background-color: $color-dot-grey;
      border-radius: 50%;
    }
  }

  @include mobile {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 8px;

    li {
      margin-right: 8px;

      &::after {
        top: 4px;
        right: -6px;
        width: 2px;
        height: 2px;
      }
    }
  }
}
