@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.edu-track {
  padding-bottom: 50px;

  .swiper-wrapper {
    @media screen and (min-width: 768px) {
      transform: translate3d(0, 0, 0) !important;
    }
  }

  .swiper-slide {
    @media screen and (min-width: 768px) {
      flex-shrink: 1;
      width: auto;
    }

    @include mobile {
      flex-shrink: 1;
    }
  }
}

.edu-track__title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.edu-track__tabs-controls {
  display: flex;
  justify-content: center;

  @include mobile {
    justify-content: flex-start;
  }
}

.edu-track__tabs-link {
  position: relative;
  font-family: $font-secondary;
  color: #D9D9D9;
  font-size: 36px;
  line-height: 1;
  font-weight: 500;
  margin-right: 50px;
  padding-bottom: 14px;

  &::before {
    @include pseudo();

    bottom: 6px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #D9D9D9;
  }

  &::after {
    @include css-triangle($color-dot-blue, down, 8px);

    display: none;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);
  }

  &:last-child {
    margin-right: 0;
  }

  &.is-active {
    color: $color-dot-dark;

    &::before {
      background-color: $color-dot-blue;
    }

    &::after {
      display: block;
    }
  }

  @include tablet {
    font-size: 26px;
  }

  @include mobile {
    font-size: 36px;
    margin-right: 0;
    white-space: nowrap;
  }
}

.edu-track__tabs-content {
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 65px;
  padding-right: 65px;

  background-repeat: no-repeat;
  background-position: center top 106px;
  background-size: contain;
  background-image: url("../../../assets/images/edu-track/edu-track-line@2x.png");

  & > span {
    position: absolute;
    top: 85px;
    color: $color-dot-grey;
    font-size: 10px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }

  @include tablet {
    background-image: none;

    & > span {
      display: none;
    }
  }

  @include mobile {
    display: block;
    padding: 0;
  }
}

.edu-track__tabs-item {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 60px;
  padding-top: 77px;

  @include tablet {
    gap: 20px 20px;
    padding-top: 40px;
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 32px;
  }
}

.edu-track__item {
  p {
    max-width: 200px;
    font-weight: 500;
  }

  @include mobile {
    display: grid;
    grid-template-columns: 75px 1fr;
    column-gap: 10px;

    p {
      max-width: none;
      line-height: 1.28;
      margin-bottom: 0;
    }
  }
}

.edu-track__image {
  max-width: 150px;
  border-radius: 10px;

  @include mobile {
    grid-row: 1 / 3;
    max-width: 75px;
    border-radius: 5px;
  }
}

.edu-track__type {
  display: block;
  color: $color-dot-grey;
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-top: 10px;

  @include mobile {
    margin-top: 0;
  }
}

.edu-track__btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 5;

  @include mobile {
    margin: 0 auto;
    padding-top: 8px;
  }
}

.edu-track__btn {
  display: block;
  width: 315px;
  margin-bottom: 20px;
}
