@import 'app/styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal {
  width: 452px !important;
  @media screen and (max-width: map-get($breakpoints, md)) {
    max-width: 347px !important;
    width: unset;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.borderless {
  border-bottom: var(--primary-btn-color) solid 1px;
  border-radius: 0;
  padding: 5px 0;

  &::placeholder {
    color: var(--gray-color);
    opacity: 1;
  }
}

.maskBorderless {
  border: none;
  border-bottom: var(--primary-btn-color) solid 1px;
  border-radius: 0;
  padding: 5px 0;

  &:focus,
  &:hover {
    border: none;
    border-bottom: var(--primary-btn-color) solid 1px;
    border-radius: 0;
  }
}

.input::placeholder {
  color: var(--gray-color);
  opacity: 1;
}

.error {
  color: red;
}

.hidden {
  display: none;
}
