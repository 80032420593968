.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.titleDefault {
  color: black;
  font-size: 16px;
  line-height: 24px;
}

.titleToday {
  color: var(--primary-btn-color);
}

.descr {
  font-size: 12px;
}